import dayjs from 'dayjs'
import { map, property } from 'lodash/fp'
import { compare } from 'shared/utils'
import { Sample, SampleSortOption } from './types'

export const getAdditionalResultsText: (additionalResults: Array<{ analyte: string }>) => string = (
  additionalResults
) => {
  const count = additionalResults.length

  return map(property(['analyte']))(additionalResults)
    .slice(0, 3)
    .join(', ')
    .concat(count > 3 ? '...' : '')
}

export const sortSamplesByOption: (
  samples: Array<Sample>,
  sortOption: SampleSortOption
) => Array<Sample> = (samples, sortOption) => {
  return samples.sort((a: Sample, b: Sample) => {
    if (sortOption === SampleSortOption.Reported) {
      return compare(dayjs(a.order.reported), dayjs(b.order.reported), 'descending')
    }

    if (sortOption === SampleSortOption.Farm) {
      return compare(a.order.farm, b.order.farm)
    }

    if (sortOption === SampleSortOption.Requestor) {
      return compare(a.order.rep.name, b.order.rep.name)
    }

    if (sortOption === SampleSortOption.PreviousCrop) {
      return compare(a.field.previousCrop, b.field.previousCrop)
    }

    if (sortOption === SampleSortOption.CurrentCrop) {
      return compare(a.field.currentCrop, b.field.currentCrop)
    }

    return 1
  })
}
