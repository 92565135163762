import { FC, ReactNode } from 'react'
import { map } from 'lodash/fp'
import { EMPTY_STATE_STRING } from 'shared/constants'

const LabeledValue: FC<{
  label: string
  value?: ReactNode
  valueDetail?: string | number
  className?: string
}> = ({ label, value, valueDetail, className = 'flex flex-col  gap-y-1' }) => (
  <div className={className}>
    <dt className="body-small text-text-secondary">{label}</dt>
    <div className="flex flex-col gap-y-0 body-normal">
      <dd>{value || EMPTY_STATE_STRING}</dd>
      {valueDetail && <dd className="text-xs">{valueDetail}</dd>}
    </div>
  </div>
)

export const LabeledValueCollection: FC<{
  collection: Array<[string, ReactNode, ReactNode?]>
  classNames: { container?: string; element?: string }
}> = ({ collection, classNames = {} }) => {
  const { container, element } = classNames
  return (
    <dl className={container}>
      {map(([label, value, detail]) => (
        <LabeledValue
          label={label}
          value={value}
          valueDetail={detail}
          className={element}
          key={label}
        />
      ))(collection)}
    </dl>
  )
}

export default LabeledValue
