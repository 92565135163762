import { FunctionComponent, ReactElement } from 'react'
import { DocumentNode, useQuery } from '@apollo/client'
import { mapValues, omit } from 'lodash'
import { ALL_FILTER_OPTION } from 'shared/constants'
import { get } from 'shared/utils'
import { Error, Loading } from 'shared/components/Placeholders'
import { AnyFacet, FacetName, FacetValue } from 'utils/types'

export type Facets = Record<FacetName, Array<FacetValue>>

export type FacetsConfig = {
  [key: string]: {
    optional: boolean
  }
}

// TODO: add docs
export const appendAllOption: (facets: Facets, config: FacetsConfig) => Facets = (facets, config) =>
  mapValues(facets, (values: FacetValue[] | null, key: FacetName) => {
    return config[key]?.optional
      ? [
          {
            key: ALL_FILTER_OPTION,
            label: ALL_FILTER_OPTION,
          },
          ...(values || []),
        ]
      : values
  }) as unknown as Facets

const FacetsProvider: FunctionComponent<{
  query: DocumentNode
  facetsKey: string
  render: (facets: AnyFacet) => ReactElement
}> = ({ query, facetsKey, render }) => {
  const { loading, error, data } = useQuery(query, {})

  if (loading) return <Loading testId="Loading" />
  if (error) return <Error />

  const facets = get(data, facetsKey, {})

  return render(omit(facets, '__typename') as AnyFacet)
}

export default FacetsProvider
