// https://tailwindcss.com/docs/responsive-design
export enum Breakpoint {
  sm = '640px',
  md = '768px',
  lg = '1024px',
}

export enum Color {
  // deprecated legacy colors
  // TODO: remove deprecated colors
  White60 = 'rgba(255, 255, 255, 0.6)',
  LightBlue = '#1F94BF',
  DarkBlue = '#053451',
  Gray = '#E5E5E5',
  Black = '#162A38',
  Black60 = 'rgba(22, 42, 56, 0.6)',
  Border = 'rgba(22, 42, 56, 0.1)',
  SelectedBackground = '#ECF9FE',
  SelectedText = '#176c8c',
  ActiveText = '#176c8c',
  Accent = '#A50069',
  Accepted = '#85CB84',
  Rejected = '#E18F8D',
  Error = '#C31F1B',
  Success = '#38a538',
  ScatterPointSelected = '#2D9CDB',
  ScatterPointDefault = '#A392B3',
  Disabled = 'rgba(0, 0, 0, 0.26)',

  // new brand and accent colors
  AccentBlue25 = '#F1F8FE',
  AccentBlue500 = '#0067B2',
  BrandBlue50 = '#E6EDF1',
  BrandBlue500 = '#004976',
  BrandGreen500 = '#0D8B3A',
  BrandNavy300 = '#5B6670',
  TextPrimary = '#0A1B2A',
  TextSecondary = '#5B6670',
  BorderDefault = '#B3B8BD',
  BorderActive = '#004976',
  BackgroundSecondary = '#F5F8FA',
  ResultSuccess = '#C2E7B0',
  ResultWarning = '#FBDCB0',
  ResultError = '#F1C3C7',
  White = '#FFFFFF',
  LinkDefault = '#0067B2',
  ButtonDefault = '#0D8B3A',
  WarningRed = '#D33F49',
}

export enum FontSize {
  Base = '1rem',
}

export enum FontWeight {
  SemiBold = 600,
}

export enum BorderRadius {
  md = '0.375rem',
  '2xl' = '1rem',
}

export enum DateRange {
  Today = 'TODAY',
  Yesterday = 'YESTERDAY',
  Week = 'WEEK',
  Month = 'MONTH',
  Year = 'YEAR',
  Custom = 'CUSTOM',
}
