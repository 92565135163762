import { FC, ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'
import { COLOUR_CODING_DISCLAIMER, MAGNESIUM_DISCLAIMER, NO_CROP_INFORMATION } from 'copy'

export const Asterisk: ReactElement = <sup style={{ top: '-0.3em', paddingRight: 1 }}>*</sup>

const Disclaimer: FC<{ copy: string; asterisk?: boolean; className?: string }> = ({
  copy,
  asterisk = false,
  className,
}) => (
  <p className={twMerge('text-text-secondary', className)}>
    {asterisk && Asterisk}
    {copy}
  </p>
)

export const ColourCodingDisclaimer: FC = () => <Disclaimer copy={COLOUR_CODING_DISCLAIMER} />
export const MagnesiumDisclaimer: FC = () => <Disclaimer copy={MAGNESIUM_DISCLAIMER} />
export const NoCropInformation: FC = () => <Disclaimer copy={NO_CROP_INFORMATION} asterisk />

export default Disclaimer
