import { FunctionComponent, ReactNode } from 'react'

const Group: FunctionComponent<{ title?: string; subtitle?: string; children?: ReactNode }> = ({
  title,
  subtitle,
  children,
}) => (
  <div>
    {children && title && (
      <div className="mb-lg">
        <h2 className="mb-sm">{title}</h2>
        {subtitle && <p className="text-sm text-text-secondary">{subtitle}</p>}
      </div>
    )}
    <div className="flex flex-col gap-y-sm">{children}</div>
  </div>
)

export default Group
