import { FC, Fragment } from 'react'
import { twMerge } from 'tailwind-merge'
import { map } from 'lodash/fp'
import Results from 'shared/assets/png/help/results.png'
import { LinkElement, LinkPrimary } from 'shared/components/Link'
import { SidebarPage } from 'shared/components/Page'
import { CarouselCardBody, HelpType, Label } from 'components/HelpCarousel'
import { buildResourceUrl, getTitle } from 'utils'

type HelpCardData = [HelpType, string, string]

const EmailLink: FC<{ email: string }> = ({ email }) => (
  <a
    href={`mailto:${email}`}
    target="_blank"
    rel="noreferrer"
    className="text-link-default underline font-semibold"
  >
    {email}
  </a>
)

const HelpCard: FC<{ type: HelpType; description: string; to: string; className?: string }> = ({
  type,
  description,
  to,
  className,
}) => (
  <LinkElement
    to={to}
    className={twMerge('flex flex-col gap-y-xxs p-sm border rounded-xl shadow', className)}
  >
    <Label type={type} />
    <p>{description}</p>
  </LinkElement>
)

const cards: Array<HelpCardData> = [
  [
    'Doc',
    'Interpreting fertiliser and lime recommendations',
    buildResourceUrl('interpreting-recommendations'),
  ],
  [
    'Doc',
    'Exporting results for a custom date range',
    buildResourceUrl('custom-date-range-export'),
  ],
  ['Doc', 'Downloading all results for a single order', buildResourceUrl('downloading-results')],
  ['Doc', 'Exporting results for import to Gatekeeper', buildResourceUrl('export-to-gatekeeper')],
  ['Doc', 'Exporting results for import to Muddy Boots', buildResourceUrl('export-to-muddy-boots')],
  [
    'Doc',
    'Downloading the published report with recommendations',
    buildResourceUrl('download-report-recommendations'),
  ],
  [
    'Doc',
    'Updating your name, address, or contact info',
    buildResourceUrl('updating-personal-info'),
  ],
  ['Doc', 'Getting support', buildResourceUrl('hub-getting-support')],
  ['Link', 'NRM: Agronomic and Environmental Laboratory Analysis', 'https://cawood.co.uk/nrm/'],
  ['Link', 'NRM: Laboratory Tests', 'https://cawood.co.uk/nrm/services/'],
  ['Link', 'NRM: Soil Summary Interactive Map', 'http://soilsummary.enidata.co.uk/'],
  ['Link', 'NRM: Order Supplies', 'https://cawood.co.uk/order-supplies/'],
  ['Link', 'NRM: Book Courier', 'https://cawood.co.uk/book-sample-collection/'],
  ['Link', 'NRM: Accreditations ', 'https://cawood.co.uk/about-cawood/accreditations/'],
  ['Link', 'AHDB: RB209 Guidelines', 'https://ahdb.org.uk/nutrient-management-guide-rb209'],
  [
    'Link',
    'AHDB: Biological Tests for Soil Health',
    'https://ahdb.org.uk/knowledge-library/biological-tests-for-soil-health',
  ],
  [
    'Link',
    'DEFRA: Sustainable Farming Incentive (SFI)',
    'https://defrafarming.blog.gov.uk/2023/09/13/sfi-pinned-post/',
  ],
  ['Link', 'BASIS: Sustainable Land Management Training', 'https://basis-reg.co.uk/index'],
]

export const Help: FC = () => (
  <SidebarPage testId="Help" title={getTitle('Help')} heading="Help">
    {[
      <Fragment key="body">
        <div className="flex flex-col grow md:grid md:grid-cols-2 lg:grid-cols-3 gap-md md:gap-lg">
          {map((card: HelpCardData) => {
            const [type, description, to] = card
            return (
              <HelpCard
                type={type}
                description={description}
                key={`${type}-${description}`}
                to={to}
              />
            )
          })(cards)}
        </div>
      </Fragment>,
      <Fragment key="sidebar">
        <div className="flex flex-col lg:max-w-xs h-fit gap-y-md p-lg bg-bg-secondary md:rounded-xl">
          <div className="flex flex-col gap-y-xs text-lg text-text-secondary !font-semibold leading-5">
            <div>If you have any queries please contact us on:</div>
            <div className="text-text-primary">01344 886338</div>
          </div>
          <div className="flex flex-col md:max-lg:grid md:max-lg:grid-cols-3 md:max-lg:gap-x-lg gap-y-md [&>*]:text-sm [&>*]:text-text-primary">
            <div>
              <div>For queries on using the portal:</div>
              <LinkPrimary to="https://cawood.co.uk/hub-enquiries/">Contact us</LinkPrimary>
            </div>
            <div>
              <div>For technical and analytical support:</div>
              <EmailLink email="enquiries@nrm.uk.com" />
            </div>
            <div>
              <div>For invoicing queries:</div>
              <EmailLink email="credit.control@cawood.co.uk" />
            </div>
          </div>
        </div>
      </Fragment>,
      <Fragment key="hero">
        <div className="flex flex-col gap-y-md">
          <h2>Getting Started</h2>
          <CarouselCardBody
            element={{
              type: 'Doc',
              src: buildResourceUrl('viewing-results'),
              copy: 'Reviewing your most recent orders and results',
              img: Results,
            }}
          />
        </div>
      </Fragment>,
    ]}
  </SidebarPage>
)
