import { ComponentType, FunctionComponent, ReactElement, useState } from 'react'
import MaterialSelect, { SelectChangeEvent } from '@mui/material/Select'
import InputBase from '@mui/material/InputBase'
import FormControl from '@mui/material/FormControl'
import { MenuItem, withStyles } from '@material-ui/core'
import { styled } from '@mui/material/styles'
import { twMerge } from 'tailwind-merge'
import { flow, fromPairs, map, pick, values } from 'lodash/fp'
import FilterArrow from 'shared/assets/svg/filter-arrow.svg'
import { Color } from 'shared/enums'
import { StyleObject } from 'shared/types'

export type FormSelectOption = {
  label: string
  value: string
}

export const LABEL_GAP = 'gap-y-xs'
export const LABEL_CLASSES = 'text-sm font-semibold whitespace-nowrap'

const SELECT_HELP = 'Select one'
const MULTI_SELECT_HELP = 'Select one or more'

export const MULTI_SELECT_DEFAULT = 'All'

// for material UI v4 only
// after upgrading MenuItem to MUI v5 (@mui/material/MenuItem) the font family rule will come from the global theme (so `withStyles` can be removed)
const StyledMenuItem = withStyles({
  root: {
    fontFamily: 'Montserrat',
    margin: 4,
    borderRadius: 6,
    '&:hover': {
      background: Color.AccentBlue25,
      color: Color.TextPrimary,
    },
    '&.Mui-selected': {
      background: Color.BrandBlue500,
      color: Color.White,
    },
    '&.Mui-selected:hover': {
      background: Color.BrandBlue500,
      color: Color.White,
      opacity: '90%',
    },
  },
})(MenuItem)

const getSelectedLabels: (
  selectOptions: Array<FormSelectOption>,
  selectedValues: Array<string>
) => string[] = (selectOptions, selectedValues) =>
  flow([
    map((o: FormSelectOption) => {
      return [o.value, o.label]
    }),
    fromPairs,
    (obj) => pick(selectedValues, obj),
    values,
  ])(selectOptions)

export const getSelectInput: (inputStyles?: StyleObject) => ComponentType = (inputStyles = {}) =>
  styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      color: theme.palette.text.secondary,
      position: 'relative',
      border: `${Color.BorderDefault} solid 1px`,
      fontSize: 14,
      padding: '11px 26px 7px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      appearance: 'none',
      backgroundColor: Color.White,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right 20px top 50%, 0 0',
      backgroundImage: `url(${FilterArrow})`,
      '&:focus': {
        boxShadow: 'box-shadow: 0px 0px 0px 3px rgba(53, 176, 222, 0.3)',
      },
      ...inputStyles,
    },
  }))

const SelectInput = getSelectInput()

const Select: FunctionComponent<{
  name: string
  selectOptions: FormSelectOption[]
  onChange: (e: SelectChangeEvent<string[]>, setOpen: (open: boolean) => void) => void
  value: Array<string>
  nothingSelectedText?: string
  Input?: ReactElement
  containerStyles?: StyleObject
  containerClasses?: string
  label?: string
  multiple?: boolean
  prependRenderValue?: string
}> = ({
  name,
  selectOptions,
  onChange,
  value,
  nothingSelectedText = MULTI_SELECT_HELP,
  Input = <SelectInput />,
  containerStyles = {},
  containerClasses,
  label,
  multiple = true,
  prependRenderValue,
}) => {
  const [open, setOpen] = useState<boolean>(false)

  // prevents `SyntaxError: '[id=a.b]' is not a valid selector`
  // our element names have periods from formik
  const id = name.replace('.', '-')

  return (
    <FormControl
      className={twMerge(`w-full ${LABEL_GAP}`, containerClasses)}
      style={containerStyles}
    >
      {label && <div className={LABEL_CLASSES}>{label}</div>}
      <MaterialSelect
        name={name}
        id={id}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={(e: SelectChangeEvent<string[]>) => {
          onChange(e, setOpen)
        }}
        value={value}
        multiple={multiple}
        input={Input}
        displayEmpty
        renderValue={(selectedValues: unknown) => {
          if (!Array.isArray(selectedValues)) {
            return null
          }

          const selectedLabels: string[] = getSelectedLabels(selectOptions, selectedValues)

          const element =
            selectedLabels.length === 0 ? nothingSelectedText : selectedLabels.join(', ')

          return (
            <div
              style={{
                paddingRight: '12px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {prependRenderValue && (
                <span className="font-semibold pr-xs">{prependRenderValue}</span>
              )}
              {element}
            </div>
          )
        }}
        IconComponent={() => null}
      >
        [
        <StyledMenuItem key="disabled" value="" disabled>
          {multiple ? MULTI_SELECT_HELP : SELECT_HELP}
        </StyledMenuItem>
        {selectOptions.map((option: FormSelectOption) => (
          <StyledMenuItem key={`${option.label}-${option.value}`} value={option.value}>
            {option.label}
          </StyledMenuItem>
        ))}
        ]
      </MaterialSelect>
    </FormControl>
  )
}

export default Select
