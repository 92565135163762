import { FC } from 'react'
import { isSet } from 'shared/utils'

export const QueryResultsIndicator: FC<{ length?: number; singular: string; plural?: string }> = ({
  length,
  singular,
  plural,
}) => (
  <div className={`${isSet(length) ? 'visible' : 'invisible'} text-text-secondary`}>
    <span className="font-semibold">{length}&nbsp;</span>
    <span>{length === 1 ? singular : plural || `${singular}s`}</span>
  </div>
)
