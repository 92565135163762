import { FC, ReactNode, SVGProps } from 'react'
import { NavLink as BaseNavLink } from 'react-router-dom'
import { Path } from 'AppRouter'
import { map } from 'lodash/fp'
import { ReactComponent as LargeLogo } from 'shared/assets/svg/nrm-logo-large.svg'
import { ReactComponent as SmallLogo } from 'shared/assets/svg/nrm-logo-small.svg'
import { ReactComponent as MenuIcon } from 'shared/assets/svg/menu.svg'
import { ReactComponent as SamplesIcon } from 'shared/assets/svg/samples.svg'
import { ReactComponent as OrdersIcon } from 'shared/assets/svg/orders.svg'
import { ReactComponent as ReportBuilderIcon } from 'shared/assets/svg/reports.svg'
import { Takeover } from 'shared/components/Takeover'
import { Color } from 'shared/enums'
import { AnyObject } from 'shared/types'
import AccountMenu from './AccountMenu'

const NavLink: FC<{
  text: string
  Icon: FC<SVGProps<SVGSVGElement>>
  to: string
  rest?: AnyObject
}> = ({ text, Icon, to, rest }) => (
  <BaseNavLink to={to} {...rest}>
    {({ isActive }) => (
      <div
        className={`flex gap-x-xs p-2 text-white font-semibold rounded-md hover:bg-brand-blue-600 ${
          isActive ? 'bg-brand-blue-700' : ''
        }`}
      >
        <Icon fill={Color.White} /> {text}
      </div>
    )}
  </BaseNavLink>
)

const links: () => Array<ReactNode> = () => {
  const links = [
    ['Orders', OrdersIcon, Path.Orders],
    ['Results', SamplesIcon, Path.Results],
    ['Data Export', ReportBuilderIcon, Path.DataExport],
  ]

  return map(([text, Icon, path]) => {
    return <NavLink text={text} Icon={Icon} to={path} key={text} />
  })(links)
}

export const Nav: FC = () => (
  <nav className="relative flex sticky z-50 h-20 bg-brand-navy-500 items-center justify-end pr-4 md:pr-0 md:justify-center">
    <div className="hidden md:flex md:gap-x-xl">
      <div className="text-white absolute left-md lg:left-12 top-md">
        <LargeLogo />
      </div>
      {links()}
      <div className="absolute right-md lg:right-12 top-md">
        <AccountMenu />
      </div>
    </div>
    <div className="relative flex md:hidden w-full justify-end">
      <div className="absolute left-4 top-1.5">
        <SmallLogo />
      </div>
      <div className="flex gap-x-md">
        <AccountMenu />
        <Takeover Icon={MenuIcon} body={links()} />
      </div>
    </div>
  </nav>
)
