import { ChangeEvent, FunctionComponent } from 'react'
import { Field } from 'formik'
import { StyleObject } from 'shared/types'
import SelectNative from 'shared/components/SelectNative'
import LabeledFormElement from './LabeledFormElement'
import ErrorMessage from './ErrorMessage'

export type FormSelectOption = {
  label: string
  value: string
  disabled?: boolean
}

export type FormSelectProps = {
  name: string
  options: FormSelectOption[]
  label?: string
  emptyState?: string
  displayError?: boolean
  component?: FunctionComponent
  style?: StyleObject
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void
  multiple?: boolean
  ariaLabel?: string
}

const FormSelect: FunctionComponent<FormSelectProps> = ({
  name,
  options,
  label,
  emptyState,
  displayError = true,
  component = SelectNative,
  style = {},
  onChange,
  multiple = false,
  ariaLabel,
}) => {
  const fieldProps = onChange ? { onChange } : {}

  return (
    <LabeledFormElement label={label} name={name}>
      <>
        <Field
          aria-label={ariaLabel}
          name={name}
          as={component}
          style={style}
          {...fieldProps}
          multiple={multiple}
        >
          {emptyState && <option value="">{emptyState}</option>}
          {options.map((option: FormSelectOption) => {
            const { label: optionLabel, value: optionValue, disabled } = option
            return (
              <option
                key={optionLabel}
                value={optionValue}
                data-testid={optionValue}
                disabled={disabled}
              >
                {optionLabel}
              </option>
            )
          })}
        </Field>
        {displayError && <ErrorMessage forName={name} />}
      </>
    </LabeledFormElement>
  )
}

export default FormSelect
