import { FC } from 'react'
import styled from '@emotion/styled'
import FilterArrow from 'shared/assets/svg/filter-arrow.svg'
import { Color } from 'shared/enums'
import { AnyObject } from 'shared/types'

const StyledSelect = styled.select`
  border: ${Color.Gray} solid 1px;
  border-radius: 4px;
  color: ${Color.Black};
  font-size: 16px;
  padding: 16px 38px 16px 20px;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 20px top 50%, 0 0;

  :focus {
    outline: none;
  }
`

const SelectNative: FC<AnyObject> = ({ multiple, style, ...props }) => {
  const baseStyles = multiple ? {} : { backgroundImage: `url(${FilterArrow})` }
  return <StyledSelect multiple={multiple} {...props} style={{ ...baseStyles, ...style }} />
}

export default SelectNative
