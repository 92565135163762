import { FC } from 'react'
import { gql, useQuery } from '@apollo/client'
import { map } from 'lodash/fp'
import { Path } from 'AppRouter'
import { Error, Loading } from 'shared/components/Placeholders'
import useFilterFactory from 'shared/hooks/useFilterFactory'
import useCustomDateFilter from 'shared/hooks/useCustomDateFilter'
import { CardContainer } from 'shared/components/Card'
import Page from 'shared/components/Page'
import FacetsProvider, { appendAllOption, Facets } from 'shared/components/FacetsProvider'
import { OrderCard } from 'components/OrderCard'
import { QueryResultsIndicator } from 'components/QueryResultsIndicator'
import { getQueryValue } from 'utils/api'
import { getTitle } from 'utils'
import { Order } from 'utils/types'

const FACETS_QUERY = gql`
  query {
    facets {
      farm {
        label
        key
      }
      requestor {
        label
        key
      }
    }
  }
`

const ORDERS_QUERY = gql`
  query ($endTime: DateTime!, $farm: [String], $requestor: [String], $startTime: DateTime!) {
    orders(endTime: $endTime, farm: $farm, requestor: $requestor, startTime: $startTime) {
      externalId
      id
      farm
      reported
      rep {
        id
        code
        name
      }
    }
  }
`

const OrdersPage: FC<{ facets: Facets }> = ({ facets }) => {
  const { Filters, selectedFacets } = useFilterFactory(Path.Orders, {
    farm: facets.farm,
    requestor: facets.requestor,
  })

  const { farm, requestor } = selectedFacets

  const {
    Component: CustomDateRange,
    startTime,
    endTime,
    key,
  } = useCustomDateFilter(Path.Orders, 'Reported')

  const { loading, error, data } = useQuery(ORDERS_QUERY, {
    variables: {
      endTime,
      farm: getQueryValue(farm),
      requestor: getQueryValue(requestor),
      startTime,
    },
    fetchPolicy: 'no-cache',
  })

  if (loading) return <Loading />
  if (error) return <Error />

  const orders = data?.orders

  return (
    <Page testId="OrdersPage" title={getTitle('Orders')}>
      <h1>Orders</h1>
      <Filters additionalFilters={[<CustomDateRange key={key} />]} />
      <QueryResultsIndicator length={orders?.length} singular="Order" />
      <CardContainer>
        {map((order: Order) => <OrderCard order={order} key={order.id} />)(orders)}
      </CardContainer>
    </Page>
  )
}

export const Orders: FC = () => (
  <FacetsProvider
    query={FACETS_QUERY}
    facetsKey="facets"
    render={(facets) => {
      const { farm, requestor } = facets as Facets

      const withAll = appendAllOption(
        { farm, requestor },
        {
          farm: {
            optional: true,
          },
          requestor: {
            optional: true,
          },
        }
      )

      return <OrdersPage facets={withAll} />
    }}
  />
)
