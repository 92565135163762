import styled from '@emotion/styled'
import { Color } from 'shared/enums'

const styles = `
background: ${Color.White};
color: ${Color.Black};
font-size: 16px;
box-sizing: border-box;
padding: 15px 20px;
border: 1px solid rgba(22, 42, 56, 0.2);
border-radius: 4px;
outline: none;
:hover {
  border: 1px solid #1f94bf;
}
:focus {
  border: 1px solid ${Color.Black};
  color: ${Color.Black};
}
`

export const Input = styled.input`
  ${styles}
`

export const SmallInput = styled(Input)`
  padding: 7px 12px;
  font-size: 14px;
`
