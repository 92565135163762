import { FC, ReactNode, SVGProps, useState } from 'react'
import { ImgButton } from 'shared/components/Button'
import { ReactComponent as CloseIcon } from 'shared/assets/svg/close.svg'
import { Color } from 'shared/enums'

export const Takeover: FC<{
  Icon: FC<SVGProps<SVGSVGElement>>
  body: ReactNode
}> = ({ Icon, body }) => {
  const [show, setShow] = useState(false)

  return (
    <>
      {!show && (
        <button
          onClick={() => {
            setShow(true)
          }}
        >
          <Icon fill={Color.White} />
        </button>
      )}
      {show && (
        <div
          className="fixed top-0 left-0 z-50 bg-brand-navy-500 w-screen h-screen flex flex-col p-4 pt-0 gap-y-4"
          onClick={() => {
            setShow(false)
          }}
        >
          <div className="h-20 flex justify-end">
            <ImgButton>
              <CloseIcon fill={Color.White} />
            </ImgButton>
          </div>
          {body}
        </div>
      )}
    </>
  )
}
