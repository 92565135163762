import { FC } from 'react'
import { useFormikContext } from 'formik'
import { remove } from 'lodash'
import { find, map, snakeCase, toUpper } from 'lodash/fp'
import { get, isSet, traverseObject } from 'shared/utils'
import { LinkButton } from 'shared/components/Button'
import { ResetButton, ToggledControlName, ToggledSelect } from 'shared/components/FormElements'
import { FormSelectOption, MULTI_SELECT_DEFAULT } from 'shared/components/Select'
import { AnyObject } from 'shared/types'
import { pluralize } from 'utils/ui'

export const getSelectOptionsMap: (
  facets: Record<string, Array<string>>
) => Record<string, Array<FormSelectOption>> = (facets) => {
  const keyFunc = (key: string) => {
    return toUpper(snakeCase(key))
  }
  const valueFunc = (key: string, values: string[]) => {
    return map((value: string) => {
      return { label: value, value }
    })(values)
  }
  const omitted = '__typename'

  return traverseObject(facets, keyFunc, valueFunc, omitted)
}

const ReportsMultiSelect: FC<{
  label: string
  name: string
  selectOptions: Array<FormSelectOption>
  defaultChecked?: boolean
  required?: boolean
}> = ({ label, name, selectOptions, defaultChecked, required }) => {
  const { setFieldValue, values } = useFormikContext()

  const checked = get(values as AnyObject, `${name}.${ToggledControlName.Checked}`)
  const valueOnReset = { [ToggledControlName.Checked]: checked, VALUE: [] }

  const selectedOptions = get(values as AnyObject, `${name}.${ToggledControlName.Value}`)

  const removeButtons = map((text: string) => {
    const option = find((option: FormSelectOption) => {
      return option.value === text
    })(selectOptions)

    if (!isSet(option)) {
      return null
    }

    const { label, value } = option

    return (
      <div
        className="flex px-xs py-xxs bg-white border rounded-xl gap-x-xxs text-accent-blue-500 text-xs"
        key={value}
      >
        <p>{label}</p>
        <ResetButton
          onClick={() => {
            setFieldValue(name, {
              ...valueOnReset,
              [ToggledControlName.Value]: remove(selectedOptions, (option) => {
                return value !== option
              }),
            })
          }}
        />
      </div>
    )
  })(selectedOptions)

  const hideFooter = !checked || removeButtons.length === 0

  const footer = hideFooter ? null : (
    <div className="flex flex-col gap-y-2">
      <div className="flex gap-x-xxs text-xs items-center">
        <p>{removeButtons.length} Selected</p>
        <div>-</div>
        {valueOnReset && (
          <LinkButton onClick={() => setFieldValue(name, valueOnReset)}>
            {pluralize('Clear selection', removeButtons.length)}
          </LinkButton>
        )}
      </div>
      <div className="flex flex-wrap gap-4">{removeButtons}</div>
    </div>
  )

  return (
    <ToggledSelect
      label={label}
      name={name}
      selectOptions={selectOptions}
      defaultChecked={required || defaultChecked}
      required={required}
      nothingSelectedText={MULTI_SELECT_DEFAULT}
      multiple
      footer={footer}
    />
  )
}

export default ReportsMultiSelect
