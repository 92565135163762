import { useEffect, useState } from 'react'

export const useCarousel: (config: { max: number }) => {
  index: number
  position: number
  max: number
  increment: () => void
  decrement: () => void
} = ({ max }) => {
  const [index, setIndex] = useState<number>(0)

  const position = index + 1
  const increment = () => setIndex(index + 1)
  const decrement = () => setIndex(index - 1)

  useEffect(() => {
    if (index < 0) {
      setIndex(max - 1)
    }

    if (index == max) {
      setIndex(0)
    }
  }, [index])

  return {
    index,
    position,
    max,
    increment,
    decrement,
  }
}
