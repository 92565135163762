import { AnyObject } from 'shared/types'
import { logError } from 'shared/utils'

export const useLocalStorage: (
  key: string,
  initialValue?: string
) => [() => string, (value: string) => void] = (key, initialValue = '') => {
  const getStoredValue = () => {
    try {
      return localStorage.getItem(key) || initialValue
    } catch (e) {
      logError(`get localStorage error: ${e}`)
      return ''
    }
  }

  const setStoredValue = (value: string) => {
    try {
      localStorage.setItem(key, value)
    } catch (e) {
      logError(`set localStorage error: ${e}`)
    }
  }

  return [getStoredValue, setStoredValue]
}

export const useSavedValue: <T>(key: string, initialValue: T) => [T, (value: T) => void] = (
  key,
  initialValue
) => {
  const [saved, setSaved] = useLocalStorage(key, JSON.stringify(initialValue))

  const storedValue = JSON.parse(saved())

  const setStoredValue: <T>(value: T) => void = (value) => {
    setSaved(JSON.stringify(value))
  }

  return [storedValue, setStoredValue]
}

export const useSavedObject: (
  key: string,
  initialValue?: AnyObject
) => [AnyObject, (value: AnyObject) => void] = (key, initialValue = {}) => {
  return useSavedValue<AnyObject>(key, initialValue)
}
