import { FC, ReactNode } from 'react'
import { ScrollRestoration } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { isString } from 'lodash'
import { Footer } from 'components/Footer'
import { Nav } from 'components/Nav'

type BuildTitleType = (appName: string) => (title?: string, options?: { short?: boolean }) => string

export const buildTitle: BuildTitleType =
  (appName) =>
  (title, options = { short: false }) => {
    const { short } = options

    if (!title) {
      return appName
    }

    if (title && short) {
      return title
    }

    return `${title} · ${appName}`
  }

export const SectionBorder: FC = () => <div className="h-px bg-border-default" />

type PageProps = {
  testId: string
  title: string
  containerClassName?: string
  bodyClassName?: string
  breadcrumbs?: ReactNode
  heading?: string | ReactNode
  children: ReactNode
}

export const SidebarPage: FC<PageProps & { children: Array<ReactNode> }> = ({
  testId,
  title,
  breadcrumbs,
  heading,
  children,
  containerClassName,
}) => {
  const [body, sidebar, hero] = children
  return (
    <Page
      testId={testId}
      title={title}
      containerClassName={containerClassName}
      breadcrumbs={breadcrumbs}
      heading={heading}
    >
      {hero && <div>{hero}</div>}
      <div className="flex gap-xl flex-col lg:flex-row">
        <div className="w-full lg:w-2/3 flex flex-col gap-y-xl md:gap-y-xxl">{body}</div>
        <div className="flex flex-col gap-y-0 w-full lg:w-auto lg:min-w-[300px] [&>*]:-mx-4 [&>*]:md:-mx-12 [&>*]:lg:mx-0 [&>*]:p-4 [&>*]:lg:p-lg [&>*]:md:max-lg:px-12">
          {sidebar}
        </div>
      </div>
    </Page>
  )
}

const Page: FC<PageProps> = ({
  testId,
  title,
  containerClassName,
  bodyClassName,
  breadcrumbs,
  heading,
  children,
}) => (
  <div data-testid={testId}>
    {title && <title>{title}</title>}
    <Nav />
    <div className={twMerge('m-4 lg:m-12 bg-white flex flex-col gap-y-xxl', containerClassName)}>
      <div className={twMerge('flex flex-col gap-y-lg', bodyClassName)}>
        {breadcrumbs}
        {heading && (isString(heading) ? <h1>{heading}</h1> : heading)}
        {children}
      </div>
      <Footer />
    </div>
    <ScrollRestoration />
  </div>
)

export default Page
