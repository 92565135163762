import { ChangeEvent, ComponentType, FunctionComponent, ReactElement } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Radio, { RadioProps } from '@material-ui/core/Radio'
import { AnyObject, StyleObject } from 'shared/types'
import { Color } from 'shared/enums'
import LabeledFormElement from './FormElements/LabeledFormElement'

export type RadioButtonOption = {
  label: string | ReactElement
  value: string
  disabled?: boolean
}

export const disabledStyle = { color: Color.Disabled }

export const getMaterialRadio: (styles: {
  rootStyles?: AnyObject
}) => ComponentType<RadioProps> = ({ rootStyles = {} }) =>
  withStyles({
    root: {
      color: 'rgba(255, 255, 255, 0.5)',
      borderRadius: '2px',
      padding: '0 8px 0 0',
      '&$checked': {
        color: Color.White,
      },
      '&:hover': {
        backgroundColor: 'transparent',
        color: Color.White,
      },
      ...rootStyles,
    },
    checked: {},
  })((props: RadioProps) => <Radio {...props} />)

export const RadioButtons: FunctionComponent<{
  name: string
  options: RadioButtonOption[]
  radioButtonStyle?: AnyObject
  label?: string
  labelStyle?: StyleObject
  groupLabelStyle?: StyleObject
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void
  selectedValue?: string
  verticalLayout?: boolean
}> = ({
  name,
  options,
  radioButtonStyle = {},
  label,
  labelStyle = {},
  groupLabelStyle = {},
  handleChange,
  selectedValue = '',
  verticalLayout = false,
}) => {
  const MaterialRadio = getMaterialRadio(radioButtonStyle)

  return (
    <LabeledFormElement label={label} name={name} style={groupLabelStyle}>
      <div
        role="group"
        aria-labelledby={`${name}-group`}
        style={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: verticalLayout ? 'column' : 'row',
        }}
      >
        {options.map((option: RadioButtonOption, index: number) => {
          const { label: optionLabel, value, disabled } = option
          const id = `${name}-${value}`
          return (
            <label
              htmlFor={id}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                ...(verticalLayout
                  ? { marginTop: index > 0 ? '8px' : 0 }
                  : { marginLeft: index > 0 ? '24px' : 0 }),
                ...labelStyle,
              }}
              key={id}
            >
              <MaterialRadio
                id={id}
                checked={selectedValue === value}
                value={value}
                name={name}
                onChange={handleChange}
                disabled={disabled}
                disableRipple
              />
              <div
                style={{
                  marginTop: '3px',
                  ...(disabled ? disabledStyle : {}),
                }}
              >
                {optionLabel}
              </div>
            </label>
          )
        })}
      </div>
    </LabeledFormElement>
  )
}

export default RadioButtons
