import { createGenerateClassName, StylesProvider } from '@material-ui/core/styles'
import {
  createTheme as createThemeV5,
  ThemeProvider as ThemeProviderV5,
} from '@mui/material/styles'
import { Color } from 'shared/enums'
import Provider from './shared/components/Provider'
import AppRouter from './AppRouter'
import './App.css'

// using MUI Core v4 with v5
// configure MUI Core v5 to keep MUI Core v4 running alongside
// https://mui.com/x/migration/migration-data-grid-v4/
const generateClassName = createGenerateClassName({
  disableGlobal: true,
  seed: 'mui-jss',
})

// TODO: implement TypeScript interface
const themeV5 = createThemeV5({
  palette: {
    text: {
      primary: Color.TextPrimary,
      secondary: Color.TextSecondary,
    },
  },
  shape: {
    borderRadius: 6,
  },
  typography: {
    fontFamily: ['Montserrat'].join(','),
  },
})

const App = () => (
  <div className="text-base font-montserrat text-text-primary font-medium">
    <Provider>
      <StylesProvider generateClassName={generateClassName}>
        <ThemeProviderV5 theme={themeV5}>
          <AppRouter />
        </ThemeProviderV5>
      </StylesProvider>
    </Provider>
  </div>
)

export default App
