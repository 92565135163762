import { FunctionComponent } from 'react'
import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { snakeCase, toUpper } from 'lodash/fp'
import { get, isSet, traverseObject } from 'shared/utils'
import { AnyObject } from 'shared/types'
import Group from 'shared/components/FormElements/Group'
import { INITIAL_VALUES_MAP, getColumns, getValue } from 'shared/components/ReportBuilder/utils'
import { CreateReportType, SharedFormName } from 'shared/components/ReportBuilder/types'
import ReportsMultiSelect from 'shared/components/ReportBuilder/components/ReportsMultiSelect'
import ReportBuilderPage from 'shared/components/ReportBuilder/components/ReportBuilderPage'
import { EmptyToggledControl } from 'shared/components/FormElements/ToggledControl'
import { Error, Loading } from 'shared/components/Placeholders'
import { getTitle } from 'utils'

export enum FormName {
  pH = 'ACIDITY',
  CurrentCrop = 'CURRENT_CROP',
  Farm = 'FARM',
  FieldSize = 'FIELD_SIZE',
  Lime = 'LIME',
  Mg = 'MAGNESIUM',
  MgO = 'MAGNESIUM_OXIDE',
  P2O5 = 'PHOSPHATE',
  P = 'PHOSPHORUS',
  K2O = 'POTASH',
  K = 'POTASSIUM',
  PreviousCrop = 'PREVIOUS_CROP',
  SampleName = 'SAMPLE_NAME',
  SampleNumber = 'SAMPLE_NUMBER',
  SoilType = 'SOIL_TYPE',
  StrawRemoved = 'STRAW_REMOVED',
}

export const FACETS_QUERY = gql`
  query {
    facets {
      currentCrop {
        label
        key
      }
      farm {
        label
        key
      }
      sampleName {
        label
        key
      }
      previousCrop {
        label
        key
      }
      requestor {
        label
        key
      }
      soilType {
        label
        key
      }
      strawRemoved {
        label
        key
      }
    }
  }
`

export const CREATE_DATA_EXPORT_MUTATION = gql`
  mutation ($columns: [DataExportColumns]!, $filters: DataExportFiltersInput!) {
    createDataExport(columns: $columns, filters: $filters)
  }
`

const initialValues = {
  [FormName.Farm]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.SampleName]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.SampleNumber]: INITIAL_VALUES_MAP.emptyControl,
  [FormName.SoilType]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.FieldSize]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.PreviousCrop]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.CurrentCrop]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.StrawRemoved]: INITIAL_VALUES_MAP.multiSelect,
  [FormName.P]: INITIAL_VALUES_MAP.emptyControl,
  [FormName.K]: INITIAL_VALUES_MAP.emptyControl,
  [FormName.Mg]: INITIAL_VALUES_MAP.emptyControl,
  [FormName.pH]: INITIAL_VALUES_MAP.emptyControl,
  [FormName.P2O5]: INITIAL_VALUES_MAP.emptyControl,
  [FormName.K2O]: INITIAL_VALUES_MAP.emptyControl,
  [FormName.MgO]: INITIAL_VALUES_MAP.emptyControl,
  [FormName.Lime]: INITIAL_VALUES_MAP.emptyControl,
}

const onSubmit =
  (publishPayload?: (payload: Record<string, Array<string> | AnyObject>) => void) =>
  (formikValues: AnyObject, createReport: CreateReportType) => {
    const columns = getColumns(formikValues)
    const FARM = formikValues[FormName.Farm]
    const SAMPLE_NAME = formikValues[FormName.SampleName]
    const SOIL_TYPE = formikValues[FormName.SoilType]
    const PREVIOUS_CROP = formikValues[FormName.PreviousCrop]
    const CURRENT_CROP = formikValues[FormName.CurrentCrop]
    const STRAW_REMOVED = formikValues[FormName.StrawRemoved]
    const WHEN = formikValues[SharedFormName.When]

    const filters: AnyObject = {
      currentCrop: getValue(CURRENT_CROP),
      endTime: dayjs(get(WHEN, 'max')).endOf('day').toISOString(),
      farm: getValue(FARM),
      previousCrop: getValue(PREVIOUS_CROP),
      sampleName: getValue(SAMPLE_NAME),
      soilTypeCode: getValue(SOIL_TYPE),
      startTime: dayjs(get(WHEN, 'min')).startOf('day').toISOString(),
      strawRemoved: getValue(STRAW_REMOVED),
    }

    const payload = {
      columns,
      filters,
    }

    if (isSet(publishPayload)) {
      publishPayload(payload)
    }

    createReport({
      variables: payload,
    })
  }

export const DataExport: FunctionComponent<{
  publishPayload?: (payload: Record<string, string[] | AnyObject>) => void
}> = ({ publishPayload }) => {
  const { data, loading, error } = useQuery(FACETS_QUERY)

  if (loading) return <Loading />
  if (error) return <Error />

  const { currentCrop, farm, previousCrop, sampleName, soilType, strawRemoved } = data.facets

  const selectOptionsMap = traverseObject(
    { currentCrop, farm, previousCrop, sampleName, soilType, strawRemoved },
    (key) => toUpper(snakeCase(key)),
    (key, value) => {
      return value.map((v: { label: string; key: string }) => {
        return { label: v.label, value: v.key }
      })
    },
    '__typename'
  )

  return (
    <ReportBuilderPage
      testId="ReportBuilder"
      title={getTitle('Data Export')}
      initialValues={initialValues}
      createReportMutation={CREATE_DATA_EXPORT_MUTATION}
      mutationResponseKey="createDataExport"
      onSubmit={onSubmit(publishPayload)}
    >
      <Group title="Default">
        <ReportsMultiSelect
          name={FormName.Farm}
          label="Farm"
          selectOptions={selectOptionsMap[FormName.Farm]}
          required
        />
        <ReportsMultiSelect
          name={FormName.SampleName}
          label="Field / Sample Name"
          selectOptions={selectOptionsMap[FormName.SampleName]}
          required
        />
        <EmptyToggledControl name={FormName.SampleNumber} label="Sample #" defaultChecked />
      </Group>
      <Group title="Additional data sets">
        <ReportsMultiSelect
          name={FormName.SoilType}
          label="Soil Type"
          selectOptions={selectOptionsMap[FormName.SoilType]}
          defaultChecked
        />
        <EmptyToggledControl name={FormName.FieldSize} label="Field Size" />
        <ReportsMultiSelect
          name={FormName.PreviousCrop}
          label="Previous Crop"
          selectOptions={selectOptionsMap[FormName.PreviousCrop]}
          defaultChecked
        />
        <ReportsMultiSelect
          name={FormName.CurrentCrop}
          label="Current Crop"
          selectOptions={selectOptionsMap[FormName.CurrentCrop]}
          defaultChecked
        />
        <ReportsMultiSelect
          name={FormName.StrawRemoved}
          label="Straw"
          selectOptions={selectOptionsMap[FormName.StrawRemoved]}
          defaultChecked
        />
      </Group>
      <Group
        title="Results"
        subtitle="Your data export will include the measured concentration as well as the converted AHDB RB209 index value where available."
      >
        <EmptyToggledControl name={FormName.P} label="Phosphorus (P)" defaultChecked />
        <EmptyToggledControl name={FormName.K} label="Potassium (K)" defaultChecked />
        <EmptyToggledControl name={FormName.Mg} label="Magnesium (Mg)" defaultChecked />
        <EmptyToggledControl name={FormName.pH} label="Soil pH" defaultChecked />
      </Group>
      <Group
        title="Recommendations"
        subtitle="Your data export will include the input values recommended by the AHDB RB209 guidelines. Visit the Sample Details page to download recommendation notes from the full report (PDF)."
      >
        <EmptyToggledControl
          name={FormName.P2O5}
          label={
            <div>
              P<sub>2</sub>0<sub>5</sub>
            </div>
          }
          ariaLabel="P205"
          defaultChecked
        />
        <EmptyToggledControl
          name={FormName.K2O}
          label={
            <div>
              K<sub>2</sub>O
            </div>
          }
          ariaLabel="K2O"
          defaultChecked
        />
        <EmptyToggledControl name={FormName.MgO} label="MgO" defaultChecked />
        <EmptyToggledControl name={FormName.Lime} label="Lime" defaultChecked />
      </Group>
    </ReportBuilderPage>
  )
}
