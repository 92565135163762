import { FunctionComponent, ReactNode, useEffect } from 'react'
import { useFormikContext } from 'formik'
import styled from '@emotion/styled'
import { isString } from 'lodash/fp'
import { get } from 'shared/utils'
import { AnyObject } from 'shared/types'
import { ImgButton } from 'shared/components/Button'
import SelectNative from 'shared/components/SelectNative'
import FormSelect, { FormSelectOption } from './FormSelect'
import FormMultiSelect from './FormMultiSelect'
import FormInput, { SmallInput } from './FormInput'
import FormCheckbox from './FormCheckbox'
import { Breakpoint, Color } from 'shared/enums'
import FormRadioButtons from 'shared/components/FormElements/FormRadioButtons'
import { RadioButtonOption } from '../RadioButtons'
import { ReactComponent as ResetIcon } from 'shared/assets/svg/reset.svg'

// keys used to store component state in Formik
export enum ToggledControlName {
  Checked = 'CHECKED',
  Value = 'VALUE',
}

// any value is allowed
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ToggledControlValue = any

type ToggledControlProps = {
  name: string
  label: ReactNode
  initialValue?: ToggledControlValue
  defaultChecked?: boolean
  required?: boolean
  footer?: ReactNode
  ariaLabel?: string
}

export type ToggledControlState = {
  [ToggledControlName.Checked]: boolean
  [ToggledControlName.Value]: ToggledControlValue
}

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 0.5em;
  box-sizing: border-box;
  padding: 16px;
  border-radius: 12px;

  @media (min-width: ${Breakpoint.md}) {
    min-height: 71px;
    flex-direction: row;
    align-items: center;
  }
`

const StyledLabel = styled.label`
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;

  @media (min-width: ${Breakpoint.md}) {
    flex-grow: 1;
  }
`

const maybeGetDefaultAriaLabel: (label: ReactNode) => string | undefined = (label) => {
  if (isString(label)) {
    return `${label.replace(' ', '-')} input`
  }

  return undefined
}

export const ResetButton: FunctionComponent<{ onClick: (e: Event) => void }> = ({ onClick }) => (
  <ImgButton className="flex items-center" onClick={onClick}>
    <ResetIcon fill="black" />
  </ImgButton>
)

/**
 * Displays a checkbox with a child component (e.g., FormInput, FormSelect).
 * The child component can be activated or deactivated based on the checked status of the
 * checkbox. In case, there is no child component, the checked status is used to enable
 * or disable a particular feature (e.g., merging comb cassette analyte results)
 */
const ToggledControl: FunctionComponent<
  ToggledControlProps & {
    children?: (childFieldName: string) => ReactNode
  }
> = ({ name, label, children, initialValue, defaultChecked = false, required = false, footer }) => {
  const { values, setFieldValue } = useFormikContext()

  const checkedName = `${name}.${ToggledControlName.Checked}`
  const valueName = `${name}.${ToggledControlName.Value}`
  const selected = get(values as AnyObject, checkedName, false)

  // sets initial Formik state
  useEffect(() => {
    // setting `shouldValidate` to false significantly improves render time
    setFieldValue(checkedName, defaultChecked, false)
    setFieldValue(valueName, initialValue, false)
  }, [defaultChecked, name, setFieldValue])

  return (
    <StyledContainer
      style={{
        backgroundColor: selected ? Color.AccentBlue25 : Color.White,
        border: `${selected ? Color.BorderActive : Color.BorderDefault} solid 1px`,
      }}
    >
      <StyledLabel
        htmlFor={checkedName}
        aria-label={`${selected ? 'remove' : 'add'} ${label}`.toLowerCase()}
      >
        <FormCheckbox
          id={checkedName}
          checked={selected}
          name={checkedName}
          displayError={false}
          labelStyle={{
            marginRight: '12px',
            cursor: required ? 'auto' : 'pointer',
            display: 'flex',
            alignItems: 'center',
            color: Color.TextPrimary,
            fontSize: 18,
            fontWeight: 600,
          }}
          hidden={required}
        >
          {label}
        </FormCheckbox>
      </StyledLabel>
      {children && children(valueName)}
      {footer && <div className="pt-2 md:pt-0 basis-full">{footer}</div>}
    </StyledContainer>
  )
}

export const StyledSelect = styled(SelectNative)`
  padding: 11px 146px 7px 12px;
`

export const ToggledSelect: FunctionComponent<
  ToggledControlProps & {
    selectOptions: FormSelectOption[]
    nothingSelectedText?: string
    multiple?: boolean
    component?: FunctionComponent
    initialValue?: string | string[]
  }
> = ({
  name,
  label,
  selectOptions,
  nothingSelectedText,
  defaultChecked,
  required,
  multiple = false,
  initialValue = multiple ? [] : '',
  component = StyledSelect,
  footer,
}) => {
  return (
    <ToggledControl
      name={name}
      label={label}
      initialValue={initialValue}
      defaultChecked={defaultChecked}
      required={required}
      footer={footer}
    >
      {(childName: string) => {
        return multiple ? (
          <FormMultiSelect
            name={childName}
            selectOptions={selectOptions}
            nothingSelectedText={nothingSelectedText}
          />
        ) : (
          <FormSelect
            ariaLabel={`${label} options`.toLowerCase()}
            name={childName}
            options={selectOptions}
            component={component}
            displayError={false}
            style={{ fontSize: 14 }}
          />
        )
      }}
    </ToggledControl>
  )
}

export const ToggledInput: FunctionComponent<
  ToggledControlProps & {
    placeholder?: string
    initialValue?: string
  }
> = ({ name, label, placeholder, defaultChecked, initialValue = '', ariaLabel }) => {
  return (
    <ToggledControl
      name={name}
      label={label}
      initialValue={initialValue}
      defaultChecked={defaultChecked}
    >
      {(childName: string) => {
        return (
          <FormInput
            ariaLabel={ariaLabel || maybeGetDefaultAriaLabel(label)}
            name={childName}
            placeholder={placeholder}
            as={SmallInput}
            displayError={false}
          />
        )
      }}
    </ToggledControl>
  )
}

export const ToggledRange: FunctionComponent<
  ToggledControlProps & {
    // string included to support date ranges
    initialValue?: {
      min?: number | string
      max?: number | string
    }
    type?: string
  }
> = ({ name, label, defaultChecked, initialValue = { min: '', max: '' }, type = 'number' }) => {
  return (
    <ToggledControl
      name={name}
      label={label}
      initialValue={initialValue}
      defaultChecked={defaultChecked}
    >
      {(childName: string) => {
        return [
          <FormInput
            key={`${childName}.min`}
            name={`${childName}.min`}
            placeholder="Min"
            type={type}
            as={SmallInput}
            displayError={false}
            ariaLabel={`${label}-min`}
          />,
          <FormInput
            key={`${childName}.max`}
            name={`${childName}.max`}
            placeholder="Max"
            type={type}
            as={SmallInput}
            displayError={false}
            ariaLabel={`${label}-max`}
          />,
        ]
      }}
    </ToggledControl>
  )
}

// TODO: add `ariaLabel` to form radio button `${label.replace(' ', '-')} radio button group`
export const ToggledRadioButtons: FunctionComponent<
  ToggledControlProps & { options: RadioButtonOption[] }
> = ({ name, label, initialValue, defaultChecked, options }) => (
  <ToggledControl
    name={name}
    label={label}
    initialValue={initialValue}
    defaultChecked={defaultChecked}
  >
    {(childName: string) => {
      return <FormRadioButtons name={childName} options={options} displayError={false} />
    }}
  </ToggledControl>
)

export const EmptyToggledControl: FunctionComponent<ToggledControlProps> = ({
  name,
  label,
  defaultChecked,
}) => {
  return <ToggledControl name={name} label={label} defaultChecked={defaultChecked} />
}
