import dayjs from 'dayjs'
import { Color } from 'shared/enums'

export const formatDate: (date: string) => string = (date) => {
  return dayjs(date).toDate().toLocaleDateString()
}

export const getSoilIndexColor = (index: string) => {
  // Northern Ireland uses "2-" and "2+" instead of "2"
  if (index.indexOf('2') > -1) {
    return Color.ResultSuccess
  } else if (index === '1') {
    return Color.ResultWarning
  } else {
    return Color.ResultError
  }
}

export const pluralize: (unit: string, value: number) => string = (unit, value) => {
  return value === 1 ? unit : `${unit}s`
}
