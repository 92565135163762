import React, { FC, ReactElement, useState } from 'react'
import { Color } from 'shared/enums'
import { LoadingIndicator } from 'shared/components/LoadingIndicator'
import { StyleObject } from 'shared/types'
import { isSet } from 'shared/utils'
import './styles.css'

const VideoModal: FC<{
  title: string
  src: string
  buttonStyle?: StyleObject
  onClick?: () => void
  children: ReactElement
}> = ({ title, src, onClick, children }) => {
  const [modal, setModal] = useState(false)

  const toggleModal = () => {
    setModal(!modal)
  }

  return (
    <>
      {React.cloneElement(children, {
        onClick: () => {
          if (isSet(onClick) && !modal) {
            onClick()
          }
          toggleModal()
        },
      })}
      {modal ? (
        <section className="modal__bg" onClick={toggleModal}>
          <LoadingIndicator color={Color.White60} />
          <div className="modal__align">
            <div className="modal__content">
              <div className="modal__video-align">
                <iframe
                  className="modal__video-style"
                  width="900"
                  height="507"
                  frameBorder="0"
                  loading="lazy"
                  allowFullScreen
                  title={title}
                  src={`https://www.youtube.com/embed/${src}`}
                />
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </>
  )
}

export default VideoModal
