import { FC } from 'react'
import { gql, useMutation } from '@apollo/client'
import { ReactComponent as DownloadIcon } from 'shared/assets/svg/download.svg'
import { Button } from 'shared/components/Button'
import { Color } from 'shared/enums'
import { ID } from 'shared/types'
import { get, openUrl } from 'shared/utils'
import { CallToActionButtonTheme } from 'utils/types'

const DOWNLOAD_REPORT_PDF_MUTATION = gql`
  mutation ($orderId: ID!) {
    getOrderReport(orderId: $orderId)
  }
`

export const DownloadButtonReport: FC<{ orderId: ID; theme?: CallToActionButtonTheme }> = ({
  orderId,
  theme = 'primary',
}) => {
  const [getLabSampleReport] = useMutation(DOWNLOAD_REPORT_PDF_MUTATION, {
    variables: {
      orderId,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const pdfUrl = get(data, 'getOrderReport')

      if (pdfUrl) {
        openUrl(pdfUrl)
      }
    },
    onError: () => {
      alert('PDF is not available for this order.')
    },
  })

  const handleClick = () => {
    getLabSampleReport({
      variables: { orderId },
    })
  }

  return theme === 'secondary' ? (
    <Button
      className="w-full"
      styleType="secondary"
      styleSize="small"
      copy=".PDF"
      left={<DownloadIcon fill="current" />}
      onClick={(e) => {
        e.stopPropagation()
        handleClick()
      }}
    ></Button>
  ) : (
    <Button
      copy="Download Report (.PDF)"
      left={<DownloadIcon fill={Color.White} />}
      onClick={handleClick}
    />
  )
}

export default DownloadButtonReport
