/* eslint-disable @typescript-eslint/no-explicit-any */
import { FetchResult, MutationFunctionOptions, OperationVariables } from '@apollo/client'

export enum SharedFormName {
  When = 'WHEN',
}

export type RangeValueType = { min: number | string; max: number | string }

export type CreateReportType = (
  options?: MutationFunctionOptions<any, OperationVariables> | undefined
) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>
