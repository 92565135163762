import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Path } from 'AppRouter'
import { Card } from 'shared/components/Card'
import { Order } from 'utils/types'
import { formatDate as format } from 'utils/ui'
import { OrderDownloadGroup } from './OrderDownloadGroup'

export const OrderCard: FC<{ order: Order }> = ({ order }) => {
  const navigate = useNavigate()
  const { id, externalId, farm, reported, rep } = order

  return (
    <Card
      onClick={() => {
        navigate(`${Path.Orders}/${id}`)
      }}
    >
      <div className="flex flex-col gap-y-xs">
        <div className="flex">
          <div className="flex flex-col gap-y-xs flex-grow">
            <h3>{externalId}</h3>
            <div className="text-text-secondary ">
              <div>{farm}</div>
              <div>
                {rep.name} ({rep.code})
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-sm items-end md:justify-center">
            <div className="text-sm text-text-secondary">{format(reported)}</div>
            <div className="hidden sm:flex">
              <OrderDownloadGroup order={order} />
            </div>
          </div>
        </div>
        <div className="flex sm:hidden">
          <OrderDownloadGroup order={order} />
        </div>
      </div>
    </Card>
  )
}
