import { useLocation } from 'react-router-dom'
import { AnyObject } from 'shared/types'

/**
 * query param string to object
 * @returns object of query params
 * ?foo=1&bar=2&baz=3 -> {foo: "1", bar: "2", baz: "3"}
 */
const useQueryParams: () => AnyObject = () => {
  const { search } = useLocation()
  return Object.fromEntries(new URLSearchParams(search))
}

export default useQueryParams
