import { FC, ReactNode } from 'react'
import { Form, Formik } from 'formik'
import { DocumentNode, useMutation } from '@apollo/client'
import dayjs from 'dayjs'
import { AnyObject } from 'shared/types'
import { get, openUrl } from 'shared/utils'
import { LinkPrimary } from 'shared/components/Link'
import Page from 'shared/components/Page'
import Section from 'shared/components/Section'
import SocialDistancing from 'shared/components/SocialDistancing'
import { Error } from 'shared/components/Placeholders'
import {
  ExportData,
  EndDate,
  RunReportHeader,
  StartDate,
} from 'shared/components/FormElements/common'
import { buildResourceUrl } from 'utils'
import { CreateReportType, SharedFormName } from '../types'

const Header: FC = () => (
  <div className="flex flex-col gap-x-xs">
    <h1>Data Export</h1>
    <p className="w-full lg:w-2/3 pr-xl text-text-secondary">
      Create a custom report for immediate access to historical results data.
    </p>
  </div>
)

const HelpSection: FC = () => (
  <div className="flex flex-col gap-y-sm items-center bg-brand-blue-50 font-semibold p-4 md:rounded-lg -mx-4 md:-mx-0">
    <b>Related help articles:</b>
    <div className="flex flex-col gap-y-xs items-center">
      {[
        ['Custom date range exports', buildResourceUrl('custom-date-range-export')],
        ['Gatekeeper exports', buildResourceUrl('export-to-gatekeeper/')],
        ['Muddy Boots exports', buildResourceUrl('export-to-muddy-boots')],
      ].map((element: Array<string>) => {
        const [label, url] = element
        return (
          <LinkPrimary to={url} className="font-semibold whitespace-nowrap" key={url}>
            {label}
          </LinkPrimary>
        )
      })}
    </div>
  </div>
)

// NOTE: this component does not yet support templates
const ReportBuilderPage: FC<{
  testId: string
  title: string
  initialValues: AnyObject
  createReportMutation: DocumentNode
  mutationResponseKey: string
  onSubmit: (values: AnyObject, createReport: CreateReportType) => void
  children: ReactNode
}> = ({
  testId,
  title,
  initialValues,
  createReportMutation,
  mutationResponseKey,
  onSubmit,
  children,
}) => {
  const [createReport, { error: mutationError }] = useMutation(createReportMutation, {
    onCompleted: (mutationData) => {
      const csvUrl = get(mutationData, mutationResponseKey)

      if (csvUrl) {
        openUrl(csvUrl)
      }
    },
  })

  if (mutationError) return <Error />

  return (
    <Page testId={testId} title={title} heading={<Header />} bodyClassName="gap-y-xxl">
      <Formik
        initialValues={{
          ...initialValues,
          ...{
            [SharedFormName.When]: {
              min: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
              max: dayjs().format('YYYY-MM-DD'),
            },
          },
        }}
        onSubmit={(formikValues) => onSubmit(formikValues, createReport)}
      >
        {() => (
          <Form>
            <Section first className="flex flex-wrap md:flex-nowrap flex-col md:flex-row gap-12">
              <div className="flex flex-col gap-y-12 md:w-2/3">{children}</div>
              <div className="md:w-1/3">
                <RunReportHeader />
                <SocialDistancing
                  spacing="32px"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div className="flex flex-col gap-y-lg">
                    <StartDate />
                    <EndDate />
                  </div>
                  <ExportData />
                  <HelpSection />
                </SocialDistancing>
              </div>
            </Section>
          </Form>
        )}
      </Formik>
    </Page>
  )
}

export default ReportBuilderPage
