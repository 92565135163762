import { FunctionComponent, ReactNode } from 'react'
import styled from '@emotion/styled'
import { twMerge } from 'tailwind-merge'
import { StyleObject } from 'shared/types'

const SECTION_SPACING = '48px'

const StyledSection = styled.section<{ first: boolean }>`
  margin: ${SECTION_SPACING} 0;
  margin-top: ${(props) => (props.first ? 0 : SECTION_SPACING)};
`

export const SectionHeader: FunctionComponent<{ children: ReactNode; style?: StyleObject }> = ({
  children,
  style,
}) => (
  <h2 className="mb-lg" style={style}>
    {children}
  </h2>
)

const Section: FunctionComponent<{
  header?: string
  first?: boolean
  className?: string
  children?: ReactNode
}> = ({ header, first = false, className, children }) => (
  <StyledSection first={first}>
    {header && <SectionHeader>{header}</SectionHeader>}
    <div className={twMerge('flex', className)}>{children}</div>
  </StyledSection>
)

export default Section
