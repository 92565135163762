import {
  ButtonHTMLAttributes,
  ForwardRefExoticComponent,
  FC,
  ReactElement,
  useRef,
  ReactNode,
  MouseEventHandler,
} from 'react'
import { Link, LinkProps } from 'react-router-dom'
import styled from '@emotion/styled'
import { twMerge } from 'tailwind-merge'
import { Color } from 'shared/enums'
import { AnyObject, StyleObject } from 'shared/types'
import { isSet } from 'shared/utils'

type ButtonType = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> & {
  as?: ForwardRefExoticComponent<LinkProps>
  to?: string
  // onClick can take args of any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (...args: any[]) => void
}

const StyledButton = styled.button`
  box-sizing: border-box;
  font-size: 16px;
  cursor: pointer;
  font-family: ${({ theme }: AnyObject) => {
    return theme.typography.fontFamily
  }};

  @media print {
    display: none;
  }
`

export const BaseButton: FC<ButtonType> = ({ onClick, ...props }) => {
  const ref = useRef(null)
  return (
    <StyledButton
      type="button"
      {...props}
      ref={ref}
      onClick={(e) => {
        // const label = get(ref, 'current.innerText') || get(ref, 'current.ariaLabel')

        // TODO: implement tracking
        // trackEvent(EventCategory.Button, EventAction.Click, label)

        // Executes the onClick action if set by the caller
        if (isSet(onClick)) {
          onClick(e)
        }
      }}
    />
  )
}

/**
 * @deprecated use `Button` going forward
 */
export const PrimaryButton = styled(BaseButton)`
  padding: 15px 20px;
  background-color: ${Color.ButtonDefault};
  color: ${Color.White};
  font-weight: 600;
  text-align: center;
  border-radius: 4px;
  border: none;
  margin: 3px;

  :hover {
    background-color: #41be41;
  }

  :focus {
    outline: none;
    background-color: #41be41;
    border: 3px solid ${Color.White};
    box-shadow: 0px 0px 0px 3px rgba(56, 165, 56, 0.3);
    margin: 0; // stops button from moving on focus
  }

  :active {
    background-color: #2f892f;
  }

  :disabled {
    background-color: #cbcacb;
    color: rgba(22, 42, 56, 0.6);
  }
`

/**
 * @deprecated use `Button` going forward
 */
export const SecondaryButton = styled(BaseButton)`
  padding: 15px 20px;
  background: ${Color.White};
  border: 1px solid ${Color.ButtonDefault};
  color: ${Color.ButtonDefault};
  font-weight: 600;
  text-align: center;
  border-radius: 4px;
  margin: 3px;

  :hover {
    color: #1f94bf;
    border: 1px solid #1f94bf;
  }

  :focus {
    outline: none;
    color: #1f94bf;
    background: #ecf9fe;
    border: 3px solid ${Color.White};
    box-shadow: 0px 0px 0px 3px rgba(53, 176, 222, 0.3);
  }

  :active {
    color: #176c8c;
    border: 1px solid #176c8c;
  }

  :disabled {
    color: rgba(22, 42, 56, 0.5);
    border: 1px solid #cbcacb;
  }
`

export const DeleteButton = styled(SecondaryButton)`
  :hover {
    color: ${Color.Error};
    border: 1px solid ${Color.Error};
  }
`

export const TextButton = styled(BaseButton)`
  color: ${Color.Black60};
  line-height: 22px;
  background-color: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  opacity: 85%;

  :hover {
    opacity: 100%;
  }
`

export const ImgButton = styled(BaseButton)`
  background-color: transparent;
  border: none;
  outline: none;
`

const SmallButton = styled(BaseButton)`
  width: 100%;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 8px;
  border-radius: 2px;

  :focus {
    border: none;
  }
`

/*
    Button that looks like a Link
*/
export const LinkButton = styled(BaseButton)`
  display: flex;
  color: ${Color.LinkDefault};
  font-size: 12px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.12px;
  text-decoration-line: underline;
`

export const SmallPrimaryButton = SmallButton.withComponent((props: ButtonType) => (
  <PrimaryButton {...props} style={{ paddingBottom: '9.5px' }} />
))

export const SmallSecondaryButton = SmallButton.withComponent(SecondaryButton)

/*
    Link that looks like a Button
*/
export const ButtonLink: FC<{
  to: string
  Component?: FC
  style?: StyleObject
  children: string | ReactElement
}> = ({ to, Component = PrimaryButton, children, style = {}, ...props }) => (
  <Component to={to} as={Link} style={style} {...props}>
    {children}
  </Component>
)

type ButtonStyleType = 'primary' | 'secondary'
type ButtonSizeType = 'large' | 'small'

const styles: {
  primary: string
  secondary: string
} = {
  primary:
    'text-white fill-white bg-brand-green-500 hover:bg-brand-green-600 active:bg-brand-green-700 disabled:bg-opacity-50',
  secondary:
    'bg-white text-brand-green-500 hover:text-brand-green-600 active:text-brand-green-700 disabled:text-opacity-50 border border-brand-green-500 hover:border-brand-green-600 active:border-brand-green-700 fill-brand-green-500 hover:fill-brand-green-600 active:fill-brand-green-700 disabled:fill-opacity-50',
}

const getStyles: (type: ButtonStyleType, size: ButtonSizeType) => string = (type, size) => {
  const large = `${styles[type]} flex flex-nowrap gap-x-xs py-sm px-lg justify-center items-center rounded-md button-label disabled:cursor-not-allowed text-base font-semibold leading-6`
  return size === 'small' ? twMerge(large, 'text-sm leading-4 rounded-sm px-sm py-xs') : large
}

export const Button: FC<{
  type?: 'button' | 'submit' | 'reset'
  styleType?: ButtonStyleType
  styleSize?: ButtonSizeType
  copy: string
  left?: ReactNode
  right?: ReactNode
  onClick?: MouseEventHandler<HTMLButtonElement>
  className?: string
}> = ({
  type = 'button',
  styleType = 'primary',
  styleSize = 'large',
  copy,
  left,
  right,
  onClick,
  className,
}) => (
  <button
    type={type}
    className={twMerge(getStyles(styleType, styleSize), className)}
    onClick={onClick}
  >
    {left && <div className="fill-current">{left}</div>}
    <div className="whitespace-nowrap">{copy}</div>
    {right && <div className="fill-current">{right}</div>}
  </button>
)
