import { FC, Fragment, useState } from 'react'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { withStyles } from '@mui/styles'
import { ReactComponent as Account } from 'shared/assets/svg/account.svg'
import { ReactComponent as Courier } from 'shared/assets/svg/courier.svg'
import { ReactComponent as Help } from 'shared/assets/svg/help.svg'
import { ReactComponent as Supplies } from 'shared/assets/svg/supplies.svg'
import Logout from 'shared/components/Logout'
import { Color } from 'shared/enums'
import { openUrl } from 'shared/utils'
import { Path } from 'AppRouter'
import { Link } from 'enums'

const StyledMenuItem = withStyles({
  root: {
    fontWeight: 600,
    '&:hover': {
      backgroundColor: Color.BackgroundSecondary,
    },
  },
})(MenuItem)

const LogoutMenuItem = withStyles({
  root: {
    color: Color.WarningRed,
    padding: 0,
  },
})(StyledMenuItem)

const Divider: FC = () => <div className="h-px bg-border-default opacity-80" />

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (path?: string) => {
    openUrl(path)
    setAnchorEl(null)
  }

  return (
    <Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <IconButton
          onClick={handleClick}
          size="small"
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar style={{ backgroundColor: Color.BorderDefault }} />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={() => handleClose()}
        onClick={() => handleClose()}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '& .MuiList-root': {
              padding: 0,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 43, vertical: 'bottom' }}
      >
        <StyledMenuItem onClick={() => handleClose(Link.Courier)}>
          <ListItemIcon>
            <Courier />
          </ListItemIcon>
          Need a courier?
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleClose(Link.Supplies)}>
          <ListItemIcon>
            <Supplies />
          </ListItemIcon>
          Order Supplies
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem onClick={() => handleClose(Path.Account)}>
          <ListItemIcon>
            <Account />
          </ListItemIcon>
          My Account
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleClose(Path.Help)}>
          <ListItemIcon>
            <Help />
          </ListItemIcon>
          Help
        </StyledMenuItem>
        <Divider />
        <LogoutMenuItem>
          {
            // padding matches MUI but is clickable when applied here
          }
          <Logout style={{ padding: '6px 16px' }} />
        </LogoutMenuItem>
      </Menu>
    </Fragment>
  )
}
