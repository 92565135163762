import { FC } from 'react'
import { gql, useQuery } from '@apollo/client'
import { LinkPrimary } from 'shared/components/Link'
import Page from 'shared/components/Page'
import { Error, Loading } from 'shared/components/Placeholders'
import Logout from 'shared/components/Logout'
import { Link } from 'enums'
import { getTitle } from 'utils'

const CURRENT_USER_QUERY = gql`
  query {
    current_user {
      id
      rep {
        id
        account {
          id
          code
        }
        address
        code
        email
        name
      }
    }
  }
`

const padding = 'p-md'
const codeClasses = 'font-semibold'
const contactInfoLabelClasses = 'text-sm text-text-secondary'
const filledContainerClasses = `md:rounded-lg ${padding}`

const Divider: FC = () => <div className="h-px bg-border-default" />

export const Account: FC = () => {
  const { data, loading, error } = useQuery(CURRENT_USER_QUERY)

  if (loading) return <Loading />
  if (error) return <Error />

  const { account, address, code, email, name } = data.current_user.rep

  return (
    <Page
      testId="AccountPage"
      title={getTitle('Account')}
      containerClassName="mt-0 md:mt-lg lg:mt-xxl md:w-4/5 md:m-auto lg:w-2/3 lg:m-auto"
    >
      <div className="[&>*]:-mx-4 [&>*]:md:mx-0 md:flex md:flex-col md:gap-y-xl">
        <div
          className={`${filledContainerClasses} bg-accent-yellow-50 lg:p-lg flex flex-col gap-y-md md:gap-y-lg`}
        >
          <p>
            If you’d like to make edits to your account information, please contact us on: 01344
            886338 or{' '}
            <span>
              <LinkPrimary to={Link.Contact}>online</LinkPrimary>
            </span>
            .
          </p>
          <p className="text-xs text-text-secondary">
            Once authorised, updates will be made to your account and reflected here at the Hub
            within three business days.
          </p>
        </div>
        <div
          className={`${filledContainerClasses} bg-bg-secondary flex text-sm [&>*]:w-full [&>*]:flex [&>*]:gap-x-xxs [&>*]:md:justify-center`}
        >
          <div>
            Account: <div className={codeClasses}>{account.code}</div>
          </div>
          <div>
            Client Code: <div className={codeClasses}>{code}</div>
          </div>
        </div>
        <div className={`${padding} md:p-0 flex flex-col gap-y-md`}>
          <div>
            <div className={contactInfoLabelClasses}>Name</div>
            <div>{name.toUpperCase()}</div>
          </div>
          <div>
            <div className={contactInfoLabelClasses}>Email Address</div>
            <div>{email.toLowerCase()}</div>
          </div>
          <div>
            <div className={contactInfoLabelClasses}>Address</div>
            <div>
              {address.map((line: string, index: number) => {
                return <div key={index}>{line.toUpperCase()}</div>
              })}
            </div>
          </div>
          <Divider />
          <div className="flex flex-col gap-y-lg">
            <h2>Privacy & Terms</h2>
            <div className="flex flex-col gap-y-xs">
              <p>
                You agree to the <LinkPrimary to={Link.Privacy}>Privacy Policy</LinkPrimary>
              </p>
              <p>
                You agree to the <LinkPrimary to={Link.Terms}>Terms of use</LinkPrimary>
              </p>
            </div>
          </div>
          <Divider />
          <div className="w-fit">
            <Logout />
          </div>
        </div>
      </div>
    </Page>
  )
}
