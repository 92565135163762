import { FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { Color } from 'shared/enums'
import FormInput, { FormInputProps, Textarea } from 'shared/components/FormElements/FormInput'
import FormSelect from 'shared/components/FormElements/FormSelect'
import FormCheckbox from 'shared/components/FormElements/FormCheckbox'
import ErrorMessage from 'shared/components/FormElements/ErrorMessage'
import FormRadioButtons from 'shared/components/FormElements/FormRadioButtons'
import {
  ToggledSelect,
  ToggledInput,
  ToggledRange,
  ToggledControlName,
  ToggledControlState,
  ResetButton,
} from 'shared/components/FormElements/ToggledControl'

export const FieldLabel = styled.label`
  color: ${Color.Black60};
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
`

export const REQUIRED_ERROR_MESSAGE = 'This field is required'
export const REQUIRED_IF_SELECTED_ERROR_MESSAGE = 'This field is required if selected'
export const VALID_EMAIL_ERROR_MESSAGE = 'Email must be valid'

export const getExceededMaxLengthMessage: (maxLength: number) => string = (maxLength) =>
  `Exceeds max length of ${maxLength} characters`

export {
  FormInput,
  Textarea,
  FormSelect,
  FormCheckbox,
  ErrorMessage,
  FormRadioButtons,
  ToggledSelect,
  ToggledInput,
  ToggledRange,
  ToggledControlName,
  ResetButton,
}

export type { ToggledControlState }

export const PasswordInput: FunctionComponent<Omit<FormInputProps, 'type'>> = (props) => (
  <FormInput style={{ width: '100%' }} {...props} type="password" />
)
