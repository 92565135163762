import { FC } from 'react'
import { ReactComponent as DownloadIcon } from 'shared/assets/svg/download.svg'
import { Button } from 'shared/components/Button'
import { SectionHeader } from 'shared/components/Section'
import { Color } from 'shared/enums'
import { FormInput } from '.'
import { SharedFormName } from '../ReportBuilder/types'

export const RunReportHeader: FC = () => (
  <SectionHeader style={{ whiteSpace: 'nowrap' }}>Export your data</SectionHeader>
)

const dateProps = {
  type: 'date',
  style: { width: '100%' },
}

export const StartDate: FC = () => (
  <FormInput {...dateProps} name={`${SharedFormName.When}.min`} label="Start" />
)

export const EndDate: FC = () => (
  <FormInput {...dateProps} name={`${SharedFormName.When}.max`} label="End" />
)

export const ExportData: FC = () => (
  <Button
    className="w-full"
    type="submit"
    left={<DownloadIcon fill={Color.White} />}
    copy="Export Data (.CSV)"
  />
)
