import { FunctionComponent, ReactElement } from 'react'
import { useFormikContext } from 'formik'
import { get } from 'shared/utils'
import { AnyObject, StyleObject } from 'shared/types'
import Select, { FormSelectOption } from 'shared/components/Select'
import LabeledFormElement from './LabeledFormElement'

const FormMultiSelect: FunctionComponent<{
  name: string
  selectOptions: FormSelectOption[]
  label?: string
  style?: StyleObject
  Input?: ReactElement
  nothingSelectedText?: string
}> = ({ name, selectOptions, label, style = {}, Input, nothingSelectedText }) => {
  const { values, handleChange } = useFormikContext()

  return (
    <LabeledFormElement label={label} name={name}>
      <Select
        name={name}
        selectOptions={selectOptions}
        value={get(values as AnyObject, name, [])}
        nothingSelectedText={nothingSelectedText}
        onChange={handleChange}
        containerStyles={style}
        Input={Input}
      />
    </LabeledFormElement>
  )
}

export default FormMultiSelect
