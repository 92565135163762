import { FC, ReactElement } from 'react'
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client'
import { ErrorResponse, onError } from '@apollo/client/link/error'
import { LOGIN_URL } from 'shared/constants'
import { isSet } from 'shared/utils'

const REDIRECT_PARAM = 'redirect_to'

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  credentials: 'include',
})

// application assumes user is authenticated by default
// redirects to login if api response is rejected
const unauthorizedLink = onError(({ networkError }: ErrorResponse) => {
  if (
    networkError &&
    'statusCode' in networkError &&
    networkError.statusCode === 401 &&
    LOGIN_URL
  ) {
    const redirectPath = window.location.pathname
    const href = isSet(redirectPath) ? `${LOGIN_URL}?${REDIRECT_PARAM}=${redirectPath}` : LOGIN_URL
    window.location.href = href
  }
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: unauthorizedLink.concat(httpLink),
})

const Provider: FC<{
  children: ReactElement
}> = ({ children }) => <ApolloProvider client={client}>{children}</ApolloProvider>

export default Provider
