import React, { FunctionComponent, useState } from 'react'
import { ApolloConsumer, gql, useMutation } from '@apollo/client'
import { TextButton } from 'shared/components/Button'
import { Error } from 'shared/components/Placeholders'
import { LOGIN_URL } from 'shared/constants'
import { Color } from 'shared/enums'
import { StyleObject } from 'shared/types'

export type Client = {
  clearStore: () => void
}

export const clearSession: (client: Client) => void = (client) => {
  // resets the cache without refetching active queries
  client.clearStore()
  window.localStorage.clear()
}

export const redirect: () => void = () => {
  if (LOGIN_URL) {
    window.location.href = LOGIN_URL
  }
}

const LOGOUT_MUTATION = gql`
  mutation {
    logout
  }
`

const Logout: FunctionComponent<{ client: Client; style?: StyleObject }> = ({ client, style }) => {
  const [loggedOut, setLoggedOut] = useState<boolean>(false)

  const [logout, { error }] = useMutation(LOGOUT_MUTATION, {
    onCompleted: () => {
      clearSession(client)
      setLoggedOut(true)
    },
  })

  if (error) return <Error />

  if (loggedOut) {
    redirect()
    return null
  }

  return (
    <TextButton
      style={{
        display: 'flex',
        width: '100%',
        fontWeight: 'semibold',
        color: Color.WarningRed,
        ...style,
      }}
      onClick={() => logout()}
    >
      Log out
    </TextButton>
  )
}

const WithClient: FunctionComponent<{ style?: StyleObject }> = ({ style }) => (
  <ApolloConsumer>
    {(client) => {
      return <Logout client={client} style={style} />
    }}
  </ApolloConsumer>
)

export default WithClient
