import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as ArrowRight } from 'shared/assets/svg/arrow-right.svg'
import { ReactComponent as FilledCircle } from 'shared/assets/svg/filled-circle.svg'
import { Card, CardHeader } from 'shared/components/Card'
import { DetailedCell } from 'shared/components/Table'
import { Color } from 'shared/enums'
import { formatDate as format, getSoilIndexColor } from 'utils/ui'
import { getAdditionalResultsText } from 'utils/samples'
import { Sample } from 'utils/types'
import { Path } from 'AppRouter'
import { isSet } from 'shared/utils'

const ResultCell: FC<{
  index: string
  indexHighlightColor?: Color
  value: number
  unit: string
}> = ({ index, indexHighlightColor, value, unit }) => {
  const props = {
    text: index,
    textHighlightColor: indexHighlightColor,
    detailTextStyle: { whiteSpace: 'nowrap' },
    ...(isSet(value) ? { detailText: `${value} ${unit}` } : {}),
  }
  return <DetailedCell {...props} />
}

export const SampleCard: FC<{ sample: Sample }> = ({ sample }) => {
  const navigate = useNavigate()

  const {
    id,
    sampleName,
    order,
    field,
    standardSoilAnalysisResult: result,
    additionalResults,
    externalId,
  } = sample
  const { previousCrop, currentCrop } = field
  const { farm, reported, rep } = order
  const { acidity: pH, p: P, k: K, mg: Mg } = result
  const { code: repCode, name: repName } = rep

  return (
    <Card
      className="group hover:bg-accent-blue-25 md:flex-row md:p-0"
      onClick={() => {
        navigate(`${Path.Results}/${id}`)
      }}
    >
      <div className="flex flex-col gap-y-xxs md:border-r md:p-4 md:grow md:justify-stretch md:max-w-[50%]">
        <CardHeader title={sampleName} right={format(reported)} />
        <div className="hidden md:flex text-sm">{externalId}</div>
        <div className="hidden md:flex grow" />
        <div className="flex items-center text-sm font-semibold">
          <div className="truncate max-w-[50%]">{previousCrop}</div>
          <div className="px-micro">
            <ArrowRight fill={Color.TextPrimary} />
          </div>
          <div className="truncate max-w-[50%]">{currentCrop}</div>
        </div>
        <div className="flex gap-x-xxs text-sm text-text-secondary items-center">
          {farm} <FilledCircle fill={Color.BorderDefault} /> {repName} ({repCode})
        </div>
      </div>
      <div className="flex flex-col gap-y-md md:min-w-[50%]">
        <div className="grid grid-cols-4 bg-bg-secondary group-hover:bg-accent-blue-25 rounded-xl md:rounded-none md:rounded-tr-xl p-sm [&>*]:text-center [&>*]:text-text-secondary [&>*]:font-semibold md:border-b">
          <div>pH</div>
          <div>P</div>
          <div>K</div>
          <div>Mg</div>
        </div>
        <div className="grid grid-cols-4">
          <div className="flex items-center justify-center">
            <DetailedCell text={pH} />
          </div>
          <ResultCell
            index={P.index}
            indexHighlightColor={isSet(P.index) ? getSoilIndexColor(P.index) : undefined}
            value={P.measurement.value}
            unit={P.measurement.unit}
          />
          <ResultCell
            index={K.index}
            indexHighlightColor={isSet(K.index) ? getSoilIndexColor(K.index) : undefined}
            value={K.measurement.value}
            unit={K.measurement.unit}
          />
          <ResultCell
            index={Mg.index}
            indexHighlightColor={isSet(Mg.index) ? getSoilIndexColor(Mg.index) : undefined}
            value={Mg.measurement.value}
            unit={Mg.measurement.unit}
          />
        </div>
        {additionalResults?.length > 0 && (
          <div className="flex text-text-secondary gap-x-xs justify-center font-semibold text-sm md:border-t md:py-4">
            <div>Other Results:</div>
            <div className="text-accent-blue-500">
              {getAdditionalResultsText(additionalResults)}
            </div>
          </div>
        )}
      </div>
    </Card>
  )
}
