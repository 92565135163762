import { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { map, startsWith } from 'lodash/fp'
import { twMerge } from 'tailwind-merge'

type LinkProps = {
  to: string
  children: ReactNode
  className?: string
}

export const LinkPrimary: FC<LinkProps> = ({ to, children, className }) => {
  const external = startsWith('http', to)
  return (
    <Link
      to={to}
      target={external ? '_blank' : '_self'}
      rel="noopener noreferrer"
      className={twMerge(
        'inline text-link-default font-medium font-semibold underline leading-5',
        className
      )}
    >
      {children}
    </Link>
  )
}

export const LinkSecondary: FC<LinkProps> = ({ to, children, className }) => (
  <LinkPrimary to={to} className={twMerge(className, 'no-underline')}>
    {children}
  </LinkPrimary>
)

export const LinkElement: FC<LinkProps> = ({ to, children, className }) => {
  const external = startsWith('http', to)
  return (
    <Link
      to={to}
      className={twMerge('flex', className)}
      target={external ? '_blank' : '_self'}
      rel="noopener noreferrer"
    >
      {children}
    </Link>
  )
}

type PathType = { to: string; copy: string }

export const HierarchyLinks: FC<{
  paths: Array<PathType>
  separator?: string
  current: string
}> = ({ paths, separator = '/', current }) => {
  const shared = 'flex gap-x-xs'
  const links = map((path: PathType) => {
    const { to, copy } = path
    return (
      <div className={`${shared} items-center`} key={to}>
        <LinkSecondary to={to}>{copy}</LinkSecondary>
        <div>{separator}</div>
      </div>
    )
  })(paths)

  const tail = <div key={current}>{current}</div>

  return <div className={shared}>{[...links, tail]}</div>
}
