import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom'
import { Account } from 'pages/Account'
import { Samples } from 'pages/Samples'
import { SampleDetails } from 'pages/SampleDetails'
import { Orders } from 'pages/Orders'
import { OrderDetails } from 'pages/OrderDetails'
import { DataExport } from 'pages/DataExport'
import { Help } from 'pages/Help'

export enum Path {
  Account = '/account',
  DataExport = '/export',
  Help = '/help',
  Orders = '/orders',
  Results = '/results',
}

const router = createBrowserRouter([
  {
    path: Path.Account,
    element: <Account />,
  },
  {
    path: Path.Results,
    element: <Samples />,
  },
  {
    path: `${Path.Results}/:id`,
    element: <SampleDetails />,
  },
  {
    path: Path.Orders,
    element: <Orders />,
  },
  {
    path: `${Path.Orders}/:id`,
    element: <OrderDetails />,
  },
  {
    path: Path.DataExport,
    element: <DataExport />,
  },
  {
    path: Path.Help,
    element: <Help />,
  },
  {
    path: '*',
    element: <Navigate to={Path.Results} replace />,
  },
])

const AppRouter = () => <RouterProvider router={router} />

export default AppRouter
