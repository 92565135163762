import { Order } from './types'

export const downloadOrderCsv: (order: Order) => void = (order) => {
  const { id, externalId, reported } = order
  fetch(`${process.env.REACT_APP_REST_ENDPOINT}/export/order/${id}`, {
    credentials: 'include',
    headers: new Headers({
      Accept: 'text/csv',
      'Content-Type': 'text/csv',
    }),
    method: 'POST',
  })
    .then((response) => {
      return response.blob()
    })
    .then((blob) => {
      downloadFile(blob, `order-${externalId}-${reported}`)
    })
}

const downloadFile: (blob: Blob, fileName: string) => void = (blob, fileName) => {
  const href = URL.createObjectURL(blob)
  const a = Object.assign(document.createElement('a'), {
    href,
    style: 'display:none',
    download: fileName,
  })
  document.body.appendChild(a)
  a.click()
  URL.revokeObjectURL(href)
  a.remove()
}
