import { FC, Fragment, ReactNode } from 'react'
import { gql, useQuery } from '@apollo/client'
import { capitalize, map } from 'lodash/fp'
import { useParams } from 'react-router-dom'
import { LabeledValueCollection } from 'shared/components/LabeledValue'
import { HierarchyLinks, LinkPrimary, LinkSecondary } from 'shared/components/Link'
import { SectionBorder, SidebarPage } from 'shared/components/Page'
import { Loading, Error } from 'shared/components/Placeholders'
import { EMPTY_STATE_STRING } from 'shared/constants'
import { Path } from 'AppRouter'
import { isSet } from 'shared/utils'
import {
  Asterisk,
  ColourCodingDisclaimer,
  MagnesiumDisclaimer,
  NoCropInformation,
} from 'components/Disclaimer'
import DownloadButtonData from 'components/DownloadButtonData'
import DownloadButtonReport from 'components/DownloadButtonReport'
import { formatDate as format, getSoilIndexColor, pluralize } from 'utils/ui'
import {
  AdditionalResult,
  Order,
  StandardSoilAnalysisRecommendation,
  StandardSoilAnalysisResult,
} from 'utils/types'
import { getTitle } from 'utils'

export const SAMPLE_QUERY = gql`
  query ($id: ID!) {
    sample(id: $id) {
      id
      externalId
      sampleName
      soilType
      order {
        id
        externalId
        sampleCount
        farm
        received
        reported
        rep {
          name
        }
      }
      field {
        currentCrop
        previousCrop
        size {
          unit
          value
        }
        strawRemoved
      }
      standardSoilAnalysisResult {
        acidity
        p {
          index
          measurement {
            value
            unit
          }
        }
        k {
          index
          measurement {
            value
            unit
          }
        }
        mg {
          index
          measurement {
            value
            unit
          }
        }
      }
      standardSoilAnalysisRecommendation {
        p2o5 {
          kgPerHa
          unitsPerAc
        }
        k2o {
          kgPerHa
          unitsPerAc
        }
        mgo {
          kgPerHa
          unitsPerAc
        }
        lime {
          tePerHa
          tPerAc
        }
      }
      additionalResults {
        analyte
        measurement {
          ... on NumericResult {
            unit
            value
          }
          ... on NonnumericResult {
            value
          }
        }
      }
    }
  }
`

const SectionHeader: FC<{ title: string; children?: ReactNode }> = ({ title, children }) => (
  <div className="flex flex-col gap-y-xs">
    <h2>{title}</h2>
    {children}
  </div>
)

const MetaData: FC<{
  currentCrop: string
  externalId: string
  fieldSize: { value: number; unit: string }
  previousCrop: string
  sampleName: string
  soilType: string
  strawRemoved: boolean
}> = ({ currentCrop, fieldSize, previousCrop, soilType, strawRemoved }) => (
  <LabeledValueCollection
    classNames={{ container: 'grid grid-cols-2 gap-lg md:flex md:flex-wrap md:justify-between' }}
    collection={[
      ['Soil Type', soilType],
      ['Previous Crop', previousCrop],
      ['Current Crop', currentCrop],
      ['Field Size', `${fieldSize.value} ${pluralize(fieldSize.unit, fieldSize.value)}`],
      ['Straw', strawRemoved ? 'Removed' : 'Returned'],
    ]}
  />
)

const table = 'border rounded-lg overflow-hidden'
const header =
  'first:bg-bg-secondary first:overflow-hidden first:text-text-secondary first:font-semibold'
const row = 'grid px-md items-end py-sm border-t first:border-t-0'

type ResultsTableRow = [string, number | string, string?, string?, string?]

const Results: FC<{
  standardSoilAnalysisResult: StandardSoilAnalysisResult
  additionalResults: Array<AdditionalResult>
}> = ({ standardSoilAnalysisResult, additionalResults }) => {
  const { acidity, p, k, mg } = standardSoilAnalysisResult

  const standardResultRows: Array<ResultsTableRow> = [
    ['pH', acidity],
    ['P', p.measurement.value, p.measurement.unit, p.index],
    ['K', k.measurement.value, k.measurement.unit, k.index],
    ['Mg', mg.measurement.value, mg.measurement.unit, mg.index],
  ]

  const additionalResultRows: Array<ResultsTableRow> = additionalResults.map(
    (additionalResult: AdditionalResult, index: number) => {
      const { analyte, measurement } = additionalResult

      return [
        analyte,
        measurement.value,
        measurement.unit,
        undefined,
        index === 0 ? 'Additional Results' : undefined,
      ]
    }
  )

  return (
    <div className="grid gap-y-lg">
      <SectionHeader title="Results">
        <ColourCodingDisclaimer />
      </SectionHeader>
      <div className="border rounded-lg">
        {map((element: ResultsTableRow) => {
          const test = element[0]
          const value = element[1]
          const unit = element[2]
          const index = element[3]
          const label = element[4]

          const nonnumericResult = typeof value === 'string'

          return (
            <div key={`${test}-${value}`} className={`${row} grid-cols-3`}>
              <div className="grid gap-y-sm">
                {label && <div className="body-small font-semibold">{label}</div>}
                <div>{test}</div>
              </div>
              <div className="flex gap-x-sm">
                <div className="flex items-center">
                  {nonnumericResult ? capitalize(value) : value} {unit}
                </div>
              </div>
              {isSet(index) && (
                <div
                  style={{ backgroundColor: getSoilIndexColor(index) }}
                  className="flex gap-xs items-center justify-center px-xs py-micro rounded-md font-semibold w-fit"
                >
                  <div>Index</div>
                  <div>{index}</div>
                </div>
              )}
            </div>
          )
        })([...standardResultRows, ...additionalResultRows])}
      </div>
    </div>
  )
}

type RecommendationsTableElement = [ReactNode | null, (string | number)?, (string | number)?]

const RecommendationsTable: FC<{
  elements: Array<RecommendationsTableElement>
}> = ({ elements }) => (
  <div className={table}>
    {map((element: RecommendationsTableElement) => {
      const fertiliser = element[0]
      const primaryUnitResult = isSet(element[1]) ? element[1] : EMPTY_STATE_STRING
      const secondaryUnitResult = isSet(element[2]) ? element[2] : EMPTY_STATE_STRING

      return (
        <div
          key={`${fertiliser}-${primaryUnitResult}-${secondaryUnitResult}`}
          className={`${row} grid-cols-3 ${header}`}
        >
          <div>{fertiliser}</div>
          <div>{primaryUnitResult}</div>
          <div>{secondaryUnitResult}</div>
        </div>
      )
    })(elements)}
  </div>
)

const Recommendations: FC<{
  noCropCodeGiven: boolean
  standardSoilAnalysisRecommendation: StandardSoilAnalysisRecommendation
}> = ({ noCropCodeGiven, standardSoilAnalysisRecommendation }) => {
  const { p2o5, k2o, mgo, lime } = standardSoilAnalysisRecommendation || {}

  const limeLabel = noCropCodeGiven ? <div>Lime ( {Asterisk}Arable )</div> : 'Lime'

  return (
    <div className="grid gap-y-lg">
      <SectionHeader title="Recommendations">
        <MagnesiumDisclaimer />
      </SectionHeader>
      <RecommendationsTable
        elements={[
          ['Fertiliser', 'kg/ha', 'units/ac'],
          [
            <div key="p2o5">
              P<sub>2</sub>O<sub>5</sub>
            </div>,
            p2o5?.kgPerHa,
            p2o5?.unitsPerAc,
          ],
          [
            <div key="K2O">
              K<sub>2</sub>O
            </div>,
            k2o?.kgPerHa,
            k2o?.unitsPerAc,
          ],
          ['MgO', mgo?.kgPerHa, mgo?.unitsPerAc],
        ]}
      />
      <RecommendationsTable
        elements={[
          [null, 'Te/ha', 'T/ac'],
          [limeLabel, lime?.tePerHa, lime?.tPerAc],
        ]}
      />
      {noCropCodeGiven && <NoCropInformation />}
    </div>
  )
}

const OrderDetails: FC<{ order: Order }> = ({ order }) => {
  const { id, externalId, sampleCount, farm, received, reported, rep } = order

  return (
    <div className="flex flex-col gap-y-md bg-bg-secondary md:rounded-t-lg">
      <h3>Order Details</h3>
      <LabeledValueCollection
        classNames={{ container: 'grid grid-cols-2 md:max-lg:grid-cols-3 gap-md' }}
        collection={[
          [
            'Order ID',
            <LinkSecondary to={`${Path.Orders}/${id}`} key={id}>
              {externalId}
            </LinkSecondary>,
          ],
          ['Samples', sampleCount],
          ['Farm', farm],
          ['Requestor', rep.name],
          ['Received', format(received)],
          ['Reported', format(reported)],
        ]}
      />
      <div className="flex flex-col md:max-lg:flex-row [&>*]:w-full gap-sm pt-md">
        <DownloadButtonReport orderId={id} />
        <DownloadButtonData order={order} />
      </div>
    </div>
  )
}

const ReportBuilderCTA: FC = () => (
  <div className="flex flex-col items-center bg-brand-blue-50 font-semibold p-4 md:rounded-b-lg">
    <b>Need a custom export?</b>
    <LinkPrimary to={Path.DataExport} className="font-semibold">
      Try our Data Exporter
    </LinkPrimary>
  </div>
)

export const SampleDetails: FC = () => {
  const { id } = useParams<{ id: string }>()
  const { loading, error, data } = useQuery(SAMPLE_QUERY, {
    variables: {
      id,
    },
  })

  if (loading) return <Loading />
  if (error) return <Error />

  const {
    additionalResults,
    externalId,
    field,
    order,
    sampleName,
    soilType,
    standardSoilAnalysisResult,
    standardSoilAnalysisRecommendation,
  } = data.sample

  const { currentCrop, size, previousCrop, strawRemoved } = field
  const noCropCodeGiven = currentCrop === 'No Crop Code Given'

  const breadcrumbs = (
    <HierarchyLinks
      paths={[
        { to: Path.Orders, copy: 'Orders' },
        { to: `${Path.Orders}/${order.id}`, copy: order.externalId },
      ]}
      current={sampleName}
    />
  )

  return (
    <SidebarPage
      testId="SampleDetails"
      title={getTitle('Result Details')}
      breadcrumbs={breadcrumbs}
    >
      {[
        <Fragment key="body">
          <MetaData
            currentCrop={currentCrop}
            externalId={externalId}
            fieldSize={size}
            previousCrop={previousCrop}
            sampleName={sampleName}
            soilType={soilType}
            strawRemoved={strawRemoved}
          />
          <SectionBorder />
          <Results
            standardSoilAnalysisResult={standardSoilAnalysisResult}
            additionalResults={additionalResults}
          />
          <Recommendations
            noCropCodeGiven={noCropCodeGiven}
            standardSoilAnalysisRecommendation={standardSoilAnalysisRecommendation}
          />
        </Fragment>,
        <Fragment key="sidebar">
          <OrderDetails order={order} />
          <ReportBuilderCTA />
        </Fragment>,
        <div className="grid gap-y-lg" key="hero">
          <div className="flex flex-col gap-y-xs">
            <h1>{sampleName}</h1>
            <div className="flex flex-row gap-x-xs">
              <div>Lab Sample #:</div>
              <div className="font-semibold">{externalId}</div>
            </div>
          </div>
        </div>,
      ]}
    </SidebarPage>
  )
}
