import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import MaterialTable, { MTableBodyRow } from '@material-table/core'
import { TableContainer, makeStyles } from '@material-ui/core'
import { map } from 'lodash/fp'
import { TABLE_EMPTY_STATE } from 'shared/constants'
import { Color, FontSize, FontWeight } from 'shared/enums'
import { AnyObject, StyleObject } from 'shared/types'
import { parseIntOr } from 'shared/utils'
import useQueryParams from 'shared/hooks/useQueryParams'
import { TextButton } from 'shared/components/Button'
import SocialDistancing, { LayoutDirection } from 'shared/components/SocialDistancing'

const PAGE_QUERY_PARAM = 'page'

export const PAGINATED_ROWS_PER_PAGE = 10

export const usePageQueryParam: () => number = () => {
  const { [PAGE_QUERY_PARAM]: page } = useQueryParams()

  // page number to use if it isn't in the url's query string or if it's invalid.
  // for example: '/labs/results' or '/labs/orders?page=foo' or '/labs/orders?page=-1'
  const unspecifiedPage = 1

  const result = parseIntOr(page, unspecifiedPage)

  return result > 0 ? result : unspecifiedPage
}

export const getOffset: (page: number, limit: number) => number = (page, limit) => {
  return (page - 1) * limit
}

export const Pagination: FC<{
  limit: number
  fetched: number
  refetch: (obj: AnyObject) => void
  page: number
  path: string
}> = ({ limit, fetched, page, path }) => {
  const navigate = useNavigate()

  // if the response is full but consists of all the remaining records then there will be a next-page button to an empty table.
  // this is a consequence of the api not returning the total number of pages for a given limit.
  const showNextButton = fetched === limit
  const showPage = page > 1
  const showPreviousButton = page > 1

  const getButtonPath: (increment: number) => string = (increment) => {
    return `${path}?${PAGE_QUERY_PARAM}=${page + increment}`
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <SocialDistancing
        spacing="24px"
        direction={LayoutDirection.Horizontal}
        style={{ alignItems: 'center' }}
      >
        {showPreviousButton && (
          <TextButton
            onClick={() => {
              navigate(getButtonPath(-1))
            }}
          >
            Previous
          </TextButton>
        )}
        {showPage && <div style={{ color: Color.DarkBlue, fontWeight: 'bold' }}>{page}</div>}
        {showNextButton && (
          <TextButton
            onClick={() => {
              navigate(getButtonPath(1))
            }}
          >
            Next
          </TextButton>
        )}
      </SocialDistancing>
    </div>
  )
}

/*
  material table doesn't export this interface's type argument  
  its object keys can be found here: 
  https://github.com/material-table-core/core/blob/f4e77c888cf43d64473221ef20d8ae49f564e58e/types/index.d.ts#L196
*/
export type TableColumnType = AnyObject

const borderBottom = `${Color.Border} solid 1px`

// this border-bottom style override is necessary because MaterialTable's `rowStyle` object
// doesn't correctly implement the border rule
// (revisit later after lib update) -- c.eldridge:15dec2020
const useStyles = makeStyles({
  tableRow: {
    '& td': {
      borderBottom,
    },
  },
})

export const getColumns: (
  visibleColumns: TableColumnType[],
  hiddenColumnFields?: string[]
) => TableColumnType[] = (visibleColumns, hiddenColumnFields = []) => {
  // hidden columns are used to build urls for table-row click events
  const hiddenColumns = map((field: string) => {
    return { field, title: field, hidden: true }
  })(hiddenColumnFields)

  return [...hiddenColumns, ...visibleColumns]
}

export const DetailedCell: FC<{
  text: string | number
  textHighlightColor?: Color
  textStyle?: StyleObject
  detailText?: string
  detailTextStyle?: StyleObject
}> = ({ text, textHighlightColor, textStyle = {}, detailText, detailTextStyle = {} }) => (
  <div className="flex flex-col gap-y-sm text-center whitespace-nowrap">
    <div className="flex justify-center font-semibold mx-lg" style={textStyle}>
      <div
        style={{ backgroundColor: textHighlightColor || 'transparent' }}
        className="flex w-8 h-6 justify-center rounded-lg bg-result-success"
      >
        {text}
      </div>
    </div>
    {detailText && (
      <div className="text-xs" style={detailTextStyle}>
        {detailText}
      </div>
    )}
  </div>
)

type LineItem = { key: string; value: string }

export const MultilineCell: FC<{
  lineItems: Array<LineItem>
}> = ({ lineItems }) => (
  <SocialDistancing spacing="4px">
    {map((lineItem: LineItem) => {
      const { key, value } = lineItem
      return <div key={key}>{value}</div>
    })(lineItems)}
  </SocialDistancing>
)

const Table: FC<{
  columns: Array<TableColumnType>
  data: Array<AnyObject>
  onRowClick?: (e: MouseEvent, rowData: AnyObject) => void
  testId?: string
  rowStyle?: StyleObject
  conditionalRowStyle?: {
    predicate: (data: AnyObject) => boolean
    rowStyle: StyleObject
  }
}> = ({ testId, columns, data, onRowClick, rowStyle = {}, conditionalRowStyle }) => {
  const classes = useStyles()

  return (
    <TableContainer data-testid={testId}>
      <MaterialTable
        columns={columns}
        data={data}
        style={{
          border: `${Color.BorderDefault} solid 1px`,
          overflow: 'hidden',
        }}
        options={{
          toolbar: false,
          maxColumnSort: 0,
          paging: false,
          headerStyle: {
            color: Color.TextSecondary,
            fontWeight: FontWeight.SemiBold,
            fontSize: FontSize.Base,
            backgroundColor: Color.BackgroundSecondary,
          },
          rowStyle: (d: AnyObject) => {
            const predicateFunc = conditionalRowStyle?.predicate

            const style = predicateFunc && predicateFunc(d) ? conditionalRowStyle.rowStyle : {}

            return {
              height: '75px',
              verticalAlign: 'top',
              ...rowStyle,
              ...style,
            }
          },
        }}
        localization={{
          body: {
            emptyDataSourceMessage: <p>{TABLE_EMPTY_STATE}</p>,
          },
        }}
        components={{
          Row: (props) => (
            <MTableBodyRow {...props} className={classes.tableRow} onRowClick={onRowClick} />
          ),
        }}
      />
    </TableContainer>
  )
}

export default Table
