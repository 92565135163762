import { FC, ReactElement, useEffect, useState } from 'react'
import { SelectChangeEvent } from '@mui/material/Select'
import { compact, Dictionary, flow, includes, last, startCase, tail } from 'lodash'
import { map, merge, reduce } from 'lodash/fp'
import styled from '@emotion/styled'
import { Facets } from 'shared/components/FacetsProvider'
import { useSavedObject } from './useLocalStorage'
import { ensureArray, get, isSet } from 'shared/utils'
import { ALL_FILTER_OPTION } from 'shared/constants'
import { AnyObject } from 'shared/types'
import { TextButton } from 'shared/components/Button'
import SelectNative from 'shared/components/SelectNative'
import Select, { getSelectInput } from 'shared/components/Select'
import { Breakpoint } from 'shared/enums'
import { Color } from 'shared/enums'
import { FacetName, FacetValue } from 'utils/types'

type FiltersComponentType = FC<{
  additionalFilters?: ReactElement[]
}>

// TODO: will need to be a func if reused outside this portal
const MULTI_SELECT_FACET_NAMES: Array<FacetName> = [
  'currentCrop',
  'farm',
  'previousCrop',
  'requestor',
]

export const handleAllOption: (values: string[], allOption: string) => string[] = (
  values,
  allOption
) => {
  // remove all option if another option is selected
  if (values.length > 1 && values[0] === allOption) {
    return tail(values)
  }

  // return all option if it is selected
  if (last(values) === allOption) {
    return [allOption]
  }

  // no adjustment necessary for everything else
  return values
}

const isMultiSelectFacet: (facetName: string) => boolean = (facetName) =>
  includes(MULTI_SELECT_FACET_NAMES, facetName)

export type FormSelectOption = {
  label: string
  value: string
}

export const filterMargin = '16px 0 0 16px'

const StyledFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  @media (min-width: ${Breakpoint.md}) {
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
  }

  select {
    flex-grow: 1;
  }
`

const getResetForFacet: (facet: FacetTuple) => Dictionary<string> = (facet) => {
  const facetName = facet[0] as string
  const facetValues = facet[1] as FacetValue[]

  return {
    [facetName]: get(facetValues, '[0].key'),
  }
}

const getDefaultForFacet: (facet: FacetTuple, savedFacets: AnyObject) => Dictionary<string> = (
  facet,
  savedFacets
) => {
  const facetName = facet[0] as string
  const savedFacet = savedFacets[facetName]

  if (isSet(savedFacet)) {
    return {
      [facetName]: savedFacet,
    }
  }

  return getResetForFacet(facet)
}

type GetFilterElements = (
  facets: Facets,
  selectedFacets: Dictionary<string | string[]>,
  handleChange:
    | ((e: SelectChangeEvent<string[]>) => void)
    | ((e: SelectChangeEvent<string[]>, setOpen: (open: boolean) => void) => void)
) => ReactElement[]

type FacetTuple = [string, FacetValue[]]

export const FilterSelectInput = getSelectInput({
  fontSize: '16px',
  padding: '16px 38px 16px 20px',
})

const getFilterElements: GetFilterElements = (facets, selectedFacets, handleChange) => {
  return flow([
    map((facet: FacetTuple) => {
      const facetName = facet[0] as string
      const facetValues = facet[1] as FacetValue[]

      if (!facetValues || facetValues.length === 0) {
        return null
      }

      return isMultiSelectFacet(facetName) ? (
        <Select
          label={startCase(facetName)}
          name={facetName}
          key={facetName}
          value={(() => {
            const value = selectedFacets[facetName]
            return ensureArray(value)
          })()}
          onChange={
            handleChange as (
              e: SelectChangeEvent<string[]>,
              setOpen: (open: boolean) => void
            ) => void
          }
          selectOptions={map((facetValue: FacetValue) => {
            const { key, label } = facetValue
            return { label, value: key }
          })(facetValues)}
          Input={<FilterSelectInput />}
          containerStyles={{ minWidth: 250 }}
          containerClasses="md:w-11 md:grow"
        />
      ) : (
        <SelectNative
          data-testid={facetName}
          name={facetName}
          key={facetName}
          value={selectedFacets[facetName]}
          onChange={handleChange}
        >
          {map((facetValue: FacetValue) => {
            const { key, label } = facetValue
            return (
              <option key={key} value={key}>
                {label}
              </option>
            )
          })(facetValues)}
        </SelectNative>
      )
    }),
    compact,
  ])(Object.entries(facets) as Array<FacetTuple>)
}

export const useFilterFactory: (
  chartId: string,
  facets: Facets
) => {
  Filters: FiltersComponentType
  selectedFacets: Dictionary<string | string[]>
} = (chartId, facets) => {
  const [savedFacets, setSavedFacets] = useSavedObject(`${chartId}-facets`)

  const defaultSelectedFacets: Dictionary<string> = flow([
    map((facet: FacetTuple) => getDefaultForFacet(facet, savedFacets)),
    reduce(merge, {}),
  ])(Object.entries(facets))

  const resetFacets: Dictionary<string> = flow([
    map((facet: FacetTuple) => getResetForFacet(facet)),
    reduce(merge, {}),
  ])(Object.entries(facets))

  const [selectedFacets, setSelectedFacets] =
    useState<Dictionary<string | string[]>>(defaultSelectedFacets)

  useEffect(() => {
    setSavedFacets(selectedFacets)
  }, [selectedFacets, setSavedFacets])

  const handleChange = (e: SelectChangeEvent<string[]>, setOpen?: (open: boolean) => void) => {
    const { name, value } = e.target
    const facetName = name as FacetName

    // TODO: track change event
    // trackEvent(EventCategory.Filter, EventAction.Select, `${facetName}__${value}`)

    if (!isMultiSelectFacet(facetName)) {
      setSelectedFacets({ ...selectedFacets, ...{ [facetName]: value } })
      return
    }

    const allOption = ALL_FILTER_OPTION
    const values = handleAllOption(ensureArray(value), allOption)

    setSelectedFacets({ ...selectedFacets, ...{ [facetName]: values } })

    if (setOpen && last(values) === allOption) {
      setOpen(false)
    }
  }

  const FilterWrapper: FC<{
    children: ReactElement[]
    showResetButton?: boolean
  }> = ({ children, showResetButton = true }) => {
    return (
      <>
        <StyledFilterWrapper>{children}</StyledFilterWrapper>
        {showResetButton && (
          <TextButton
            // TODO: pass in button style as prop
            // TODO: pass same style props here for the filters so they are always the same (including date)
            style={{
              color: Color.Black,
              fontWeight: 'normal',
              textDecoration: 'underline',
              width: 'auto',
              textAlign: 'left',
            }}
            onClick={() => {
              setSelectedFacets(resetFacets)
            }}
          >
            Clear all filters
          </TextButton>
        )}
      </>
    )
  }

  // TODO: why doesn't the linter like extracting `FiltersComponentType` here?

  const Filters: FC<{
    additionalFilters?: ReactElement[]
  }> = ({ additionalFilters = [] }) => {
    const filterElements = [
      ...getFilterElements(facets, selectedFacets, handleChange),
      ...additionalFilters,
    ]

    return <FilterWrapper>{filterElements}</FilterWrapper>
  }

  return {
    Filters,
    selectedFacets,
  }
}

export default useFilterFactory
