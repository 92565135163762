import { FC } from 'react'
import { ReactComponent as DownloadIcon } from 'shared/assets/svg/download.svg'
import { Button } from 'shared/components/Button'
import { downloadOrderCsv } from 'utils/orders'
import { CallToActionButtonTheme, Order } from 'utils/types'

const DownloadButtonData: FC<{ order: Order; theme?: CallToActionButtonTheme }> = ({
  order,
  theme = 'primary',
}) => {
  const handleClick = (order: Order) => downloadOrderCsv(order)
  return theme === 'secondary' ? (
    <Button
      className="w-full"
      styleType="secondary"
      styleSize="small"
      copy=".CSV"
      left={<DownloadIcon fill="current" />}
      onClick={(e) => {
        e.stopPropagation()
        handleClick(order)
      }}
    />
  ) : (
    <Button styleType="secondary" copy="Download Data (.CSV)" onClick={() => handleClick(order)} />
  )
}

export default DownloadButtonData
