import { FC, MouseEventHandler, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import styled from '@emotion/styled'

export const Card: FC<{
  children: ReactNode
  className?: string
  onClick?: MouseEventHandler<HTMLDivElement>
}> = ({ children, className, onClick }) => (
  <div
    className={twMerge(
      'p-4 cursor-pointer border rounded-xl flex flex-col gap-y-2 shadow',
      className
    )}
    onClick={(e) => {
      // TODO: track card click
      onClick && onClick(e)
    }}
  >
    {children}
  </div>
)

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`

export const CardHeader: FC<{ title: string; subtitle?: string; right?: string }> = ({
  title,
  subtitle,
  right,
}) => (
  <div className="flex">
    <div>
      <h3>{title}</h3>
      {subtitle && <p className="text-text-secondary">{subtitle}</p>}
    </div>
    {right && <div className="grow text-right text-text-secondary">{right}</div>}
  </div>
)
