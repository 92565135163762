import { FC } from 'react'
import { Order } from 'utils/types'
import DownloadButtonData from './DownloadButtonData'
import DownloadButtonReport from './DownloadButtonReport'

export const OrderDownloadGroup: FC<{ order: Order }> = ({ order }) => {
  const theme = 'secondary'
  return (
    <div className="flex gap-x-2 w-full">
      <DownloadButtonReport orderId={order.id} theme={theme} />
      <DownloadButtonData order={order} theme={theme} />
    </div>
  )
}
