type Quantity = {
  value: number
  unit: string
}

type NumericResult = Quantity

type NonnumericResult = {
  value: string
}

type Measurement = NumericResult & NonnumericResult

export type AdditionalResult = {
  analyte: string
  measurement: Measurement
}

type Field = {
  previousCrop: string
  currentCrop: string
  size: Quantity
  strawRemoved: boolean
  projectedYield: 'low' | 'medium' | 'high'
}

export type StandardSoilAnalysisResult = {
  acidity: number
  p: IndexableTestResult
  k: IndexableTestResult
  mg: IndexableTestResult
}

type IndexableTestResult = {
  index: string
  measurement: Quantity
}

export type StandardSoilAnalysisRecommendation = {
  p2o5: FertiliserRecommendation
  k2o: FertiliserRecommendation
  mgo: FertiliserRecommendation
  lime: LimeRecommendation
}

type FertiliserRecommendation = {
  kgPerHa: number
  unitsPerAc: number
}

type LimeRecommendation = {
  tPerAc: number
  tePerHa: number
}

export type Sample = {
  id: number
  externalId: string
  sampleName: string
  soilType: string
  order: Order
  field: Field
  standardSoilAnalysisResult: StandardSoilAnalysisResult
  standardSoilAnalysisRecommendation: StandardSoilAnalysisRecommendation
  additionalResults: Array<AdditionalResult>
}

export type AnyFacet = SamplesFacet

export type FacetName = string
export type FacetValue = { key: string; label: string }

export type SamplesFacet = {
  currentCrop: Array<FacetValue>
  previousCrop: Array<FacetValue>
  farm: Array<FacetValue>
  requestor: Array<FacetValue>
}

type Rep = {
  name: string
  code: string
}

export type Order = {
  id: number
  externalId: string
  rep: Rep
  farm: string
  received: string
  reported: string
  sampleCount: number
}

export enum SampleSortOption {
  Reported = 'REPORTED',
  Farm = 'FARM',
  Requestor = 'REQUESTOR',
  PreviousCrop = 'PREVIOUS_CROP',
  CurrentCrop = 'CURRENT_CROP',
}

export type CallToActionButtonTheme = 'primary' | 'secondary'
