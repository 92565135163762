import { compact, flow } from 'lodash/fp'
import { AnyObject } from 'shared/types'
import { mapObject } from 'shared/utils'
import { ToggledControlName, ToggledControlState } from 'shared/components/FormElements'
import { RangeValueType } from 'shared/components/ReportBuilder/types'

export const INITIAL_VALUES_MAP = {
  range: {
    [ToggledControlName.Checked]: false,
    [ToggledControlName.Value]: { min: '', max: '' },
  },
  input: {
    [ToggledControlName.Checked]: false,
    [ToggledControlName.Value]: '',
  },
  select: {
    [ToggledControlName.Checked]: false,
    [ToggledControlName.Value]: '',
  },
  multiSelect: {
    [ToggledControlName.Checked]: false,
    [ToggledControlName.Value]: [],
  },
  emptyControl: {
    [ToggledControlName.Checked]: false,
  },
}

export const getValue: (state: ToggledControlState) => string | Array<string> | RangeValueType = (
  state
) => {
  return state[ToggledControlName.Checked] ? state[ToggledControlName.Value] : null
}

/**
 * returns the selected report builder fields for its api request
 * @param formValues form state
 * @returns array of selected fields
 */
export const getColumns: (formValues: AnyObject) => string[] = (formValues) =>
  flow([
    (arg) =>
      mapObject(arg, (key, value) => {
        return value[ToggledControlName.Checked] ? key : null
      }),
    compact,
  ])(formValues)
