import { FunctionComponent, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { map } from 'lodash/fp'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import {
  getDateRanges,
  isValidDateRange,
  toIsoDate,
  toIsoDateTime,
  toLocaleDate,
} from 'shared/utils/time'
import { deconstructFormElementRef } from 'shared/utils/ref'
import { AnyObject, ISODate, ISODateTime } from 'shared/types'
import { DateRange } from 'shared/enums'
import { Color } from 'shared/enums'
import { BaseButton, SmallPrimaryButton, SmallSecondaryButton } from 'shared/components/Button'
import SelectNative from 'shared/components/SelectNative'
import SocialDistancing, { LayoutDirection } from 'shared/components/SocialDistancing'
import { SmallInput } from 'shared/components/Input'
import { StyledErrorMessage } from 'shared/components/ErrorMessage'
import { useSavedObject } from './useLocalStorage'
import { Breakpoint } from 'shared/enums'
import { LABEL_CLASSES, LABEL_GAP } from 'shared/components/Select'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${Breakpoint.md}) {
    width: auto;
  }
`

const Menu = styled.div`
  position: absolute;
  z-index: 999;
  min-width: 100%;
  right: -8px;
  top: 82px;
  margin: 8px;
  padding: 24px 0 8px 0;
  background: ${Color.White};
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
`

const CustomSection = styled.div`
  display: flex;
  padding: 12px;
`

const InputLabel = styled.label`
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${Color.Black60};
`

const Input = styled(SmallInput)`
  border-radius: 2px;
`

const Option = styled(BaseButton)`
  color: ${Color.TextPrimary};
  line-height: 22px;
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  text-align: left;
  margin: 2px 0;
  padding: 4px 12px;
  border-radius: 6px;

  :hover {
    background-color: #f1f8fe;
  }
`

const Select = styled(SelectNative)`
  flex-grow: 1;
  min-width: 230px;
  text-align: left;
  background-color: ${Color.White};
  color: ${Color.TextSecondary};
  border-color: ${Color.BorderDefault};
`

const DateRanges: { [key: string]: string } = {
  [DateRange.Today]: 'Today',
  [DateRange.Yesterday]: 'Yesterday',
  [DateRange.Week]: 'Past 7 Days',
  [DateRange.Month]: 'Past 30 Days',
  [DateRange.Year]: 'Past 365 Days',
  [DateRange.Custom]: 'Custom',
}

const DATE_FORMAT = 'YYYY-MM-DD'

// week to date
const initialCustomStartDate = dayjs().startOf('week').format(DATE_FORMAT) // sunday
const initialCustomEndDate = dayjs().format(DATE_FORMAT) // today

const useCustomDateFilter: (
  id: string,
  label?: string,
  initialDateRange?: DateRange
) => {
  Component: FunctionComponent
  dateRange: DateRange
  startDate: ISODate
  endDate: ISODate
  startTime: ISODateTime
  endTime: ISODateTime
  key: string
} = (id, label, initialDateRange = DateRange.Week) => {
  const startDateInputRef = useRef(null)
  const endDateInputRef = useRef(null)

  const [savedDates, setSavedDates] = useSavedObject(`${id}-dates`)

  const [showMenu, setShowMenu] = useState<boolean>(false)

  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>(
    savedDates.selectedDateRange || initialDateRange
  )

  const [previousDateRange, setPreviousDateRange] = useState<DateRange>(
    savedDates.previousDateRange || initialDateRange
  )

  const [customStartDate, setCustomStartDate] = useState<ISODate>(
    savedDates.customStartDate || initialCustomStartDate
  )
  const [customEndDate, setCustomEndDate] = useState<ISODate>(
    savedDates.customEndDate || initialCustomEndDate
  )

  const [error, setError] = useState<string>()

  // clear error on select
  useEffect(() => {
    setError(undefined)
  }, [selectedDateRange])

  // close filter on select unless DateRange.Custom is selected
  useEffect(() => {
    if (selectedDateRange !== DateRange.Custom) {
      setShowMenu(false)
    }
  }, [selectedDateRange])

  useEffect(() => {
    setSavedDates({
      selectedDateRange,
      previousDateRange,
      customStartDate,
      customEndDate,
    })
  }, [setSavedDates, selectedDateRange, previousDateRange, customStartDate, customEndDate])

  const now = new Date()
  const dateRange = getDateRanges(now)(selectedDateRange)

  const values: { startDate: ISODate; endDate: ISODate; display: string } =
    selectedDateRange === DateRange.Custom
      ? {
          startDate: customStartDate,
          endDate: customEndDate,
          display: `${toLocaleDate(customStartDate)} - ${toLocaleDate(customEndDate)}`,
        }
      : {
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          display: DateRanges[selectedDateRange],
        }

  const inputProps = { type: 'date', max: toIsoDate(now) }

  const selectOptions: () => JSX.Element[] = () =>
    map((key: string) => (
      <Option
        style={key === selectedDateRange ? { backgroundColor: 'rgba(0, 0, 0, 0.08)' } : {}}
        key={key}
        value={key}
        onClick={(e: AnyObject) => {
          const selected = e.target.value

          // TODO: add tracking
          // trackEvent(EventCategory.Filter, EventAction.Select, selected)

          if (selectedDateRange !== DateRange.Custom) {
            setPreviousDateRange(selectedDateRange)
          }

          setSelectedDateRange(selected)
        }}
      >
        {DateRanges[key]}
      </Option>
    ))(Object.keys(DateRanges))

  const CustomDateFilter: FunctionComponent = () => (
    <ClickAwayListener onClickAway={() => setShowMenu(false)}>
      <Container className={LABEL_GAP}>
        <div className={LABEL_CLASSES}>{label}</div>
        <Select as="button" onClick={() => setShowMenu(true)}>
          {values.display}
        </Select>
        {showMenu && (
          <Menu>
            <div className="flex flex-col items-start px-xs">{selectOptions()}</div>
            {selectedDateRange === DateRange.Custom && (
              <div>
                <CustomSection>
                  <SocialDistancing spacing="9px" direction={LayoutDirection.Horizontal}>
                    <div>
                      <SocialDistancing spacing="8px">
                        <InputLabel>From</InputLabel>
                        <Input
                          {...inputProps}
                          ref={startDateInputRef}
                          defaultValue={customStartDate}
                        />
                        <SmallSecondaryButton
                          onClick={() => {
                            setSelectedDateRange(previousDateRange)
                            setCustomStartDate(initialCustomStartDate)
                            setCustomEndDate(initialCustomEndDate)
                          }}
                        >
                          Cancel
                        </SmallSecondaryButton>
                      </SocialDistancing>
                    </div>
                    <div>
                      <SocialDistancing spacing="8px">
                        <InputLabel>To</InputLabel>
                        <Input {...inputProps} ref={endDateInputRef} defaultValue={customEndDate} />
                        <SmallPrimaryButton
                          onClick={() => {
                            const start = deconstructFormElementRef(startDateInputRef)

                            const end = deconstructFormElementRef(endDateInputRef)

                            if (!start.valid) {
                              setError(start.message)
                              return
                            }

                            if (!end.valid) {
                              setError(end.message)
                              return
                            }

                            setCustomStartDate(start.value)
                            setCustomEndDate(end.value)

                            if (!isValidDateRange(start.value, end.value)) {
                              setError('The end date must be after the start date')
                              return
                            }

                            // TODO: add tracking
                            // trackEvent(EventCategory.Filter, EventAction.Select, `${start}__${end}`)

                            setError(undefined)
                            setShowMenu(false)
                          }}
                        >
                          Apply
                        </SmallPrimaryButton>
                      </SocialDistancing>
                    </div>
                  </SocialDistancing>
                </CustomSection>
                {error && (
                  <StyledErrorMessage style={{ padding: '0 12px' }}>{error}</StyledErrorMessage>
                )}
              </div>
            )}
          </Menu>
        )}
      </Container>
    </ClickAwayListener>
  )

  return {
    Component: CustomDateFilter,
    dateRange: selectedDateRange,
    startDate: values.startDate,
    endDate: values.endDate,
    startTime: toIsoDateTime(values.startDate),
    endTime: toIsoDateTime(values.endDate, {
      hour: 23,
      minute: 59,
      second: 59,
    }),
    key: `${id}-date-range`,
  }
}

export default useCustomDateFilter
