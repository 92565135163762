import { Children, FC, Fragment, ReactNode } from 'react'
import { StyleObject } from 'shared/types'

export enum LayoutDirection {
  Vertical = 'VERTICAL',
  Horizontal = 'HORIZONTAL',
}

/**
 * @deprecated use `gap` going forward now that it is fully supported
 */
const SocialDistancing: FC<{
  children: ReactNode
  spacing?: string
  direction?: LayoutDirection
  style?: StyleObject
  testId?: string
}> = ({ children, spacing = '6px', direction = LayoutDirection.Vertical, style = {}, testId }) => {
  const items: Array<ReactNode> = Children.toArray(children)

  return (
    <div
      data-testid={testId}
      style={{
        display: direction === LayoutDirection.Vertical ? 'block' : 'flex',
        ...style,
      }}
    >
      {items.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          {index > 0 && (
            <div
              style={{
                [direction === LayoutDirection.Vertical ? 'paddingTop' : 'paddingLeft']: spacing,
              }}
            />
          )}
          {item}
        </Fragment>
      ))}
    </div>
  )
}

export default SocialDistancing
