import { FunctionComponent, ReactElement } from 'react'
import { StyleObject } from 'shared/types'
import SocialDistancing from 'shared/components/SocialDistancing'
import { FieldLabel } from './index'

const LabeledFormElement: FunctionComponent<{
  name: string
  children: ReactElement
  label?: string
  style?: StyleObject
}> = ({ name, children, label, style = {} }) => (
  <SocialDistancing spacing="8px" style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
    {label && (
      <FieldLabel htmlFor={name} style={style}>
        {label}
      </FieldLabel>
    )}
    {children}
  </SocialDistancing>
)

export default LabeledFormElement
