import { FC, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import { ReactComponent as PlayIcon } from 'shared/assets/svg/play.svg'
import { ReactComponent as BackArrow } from 'shared/assets/svg/arrow-back.svg'
import { ReactComponent as ForwardArrow } from 'shared/assets/svg/arrow-forward.svg'
import Export from 'shared/assets/png/help/export.png'
import Fertiliser from 'shared/assets/png/help/fertiliser.png'
import Results from 'shared/assets/png/help/results.png'
import { ImgButton } from 'shared/components/Button'
import { LinkElement, LinkPrimary } from 'shared/components/Link'
import VideoModal from 'shared/components/VideoModal'
import { Color } from 'shared/enums'
import { useCarousel } from 'shared/hooks/useCarousel'
import { get, isSet } from 'shared/utils'
import { Path } from 'AppRouter'
import { buildResourceUrl } from 'utils'

export type HelpType = 'Video' | 'Doc' | 'Link'

type CollectionElement = {
  type: HelpType
  src: string
  copy: string
  img: string
}

const LABEL_BACKGROUND_MAP: Record<HelpType, string> = {
  Video: 'bg-brand-green-500',
  Doc: 'bg-brand-navy-300',
  Link: 'bg-accent-blue-500',
}

export const Label: FC<{ type: HelpType }> = ({ type }) => {
  const labelColor = LABEL_BACKGROUND_MAP[type as HelpType]
  return (
    <div
      className={`text-white font-semibold text-sm ${labelColor} w-fit px-xs py-xxs rounded-xl border-none`}
    >
      {type}
    </div>
  )
}

export const Card: FC<{ children?: ReactNode; className?: string }> = ({ children, className }) => (
  <div className={twMerge('flex flex-col gap-y-md rounded-xl bg-brand-blue-25 p-6', className)}>
    {children}
  </div>
)

const placeholderContainerClass =
  'rounded bg-brand-blue-50 w-32 h-20 flex justify-center items-center mr-md'

const imgContainerClass = 'w-[194px] h-[130px] rounded-md mr-md'

export const CarouselCardBody: FC<{ element: CollectionElement }> = ({ element }) => {
  const { type, src, copy, img } = element

  return (
    <div className="flex">
      {type === 'Video' && (
        <VideoModal key={copy} title={copy} src={src}>
          <ImgButton>
            <div className={placeholderContainerClass}>
              <PlayIcon />
            </div>
          </ImgButton>
        </VideoModal>
      )}
      {type !== 'Video' && (
        <LinkElement to={src}>
          <div
            style={{
              backgroundImage: `url(${img})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
            className={isSet(img) ? imgContainerClass : placeholderContainerClass}
          />
        </LinkElement>
      )}
      <div className="flex flex-col gap-y-xxs justify-center">
        <Label type={type} />
        <p className="self-center">{copy}</p>
      </div>
    </div>
  )
}

const HelpCarousel: FC = () => {
  const collection: Array<CollectionElement> = [
    {
      type: 'Doc',
      src: buildResourceUrl('viewing-results'),
      copy: 'Reviewing your most recent orders and results',
      img: Results,
    },
    {
      type: 'Doc',
      src: buildResourceUrl('interpreting-recommendations'),
      copy: 'Interpreting fertiliser and lime recommendations',
      img: Fertiliser,
    },
    {
      type: 'Doc',
      src: buildResourceUrl('custom-date-range-export'),
      copy: 'Exporting results for a custom date range',
      img: Export,
    },
  ]

  const { index, position, max, increment, decrement } = useCarousel({ max: collection.length })

  return (
    <Card>
      <div className="flex flex-col gap-y-4">
        <div className="flex">
          <h3 className="grow">Help</h3>
          <LinkPrimary to={Path.Help}>More Topics</LinkPrimary>
        </div>
        <CarouselCardBody element={get(collection, index, {})} />
        <div className="flex">
          <div className="grow text-text-secondary">
            {position} of {max}
          </div>
          <div className="flex gap-x-2">
            <ImgButton onClick={decrement}>
              <BackArrow fill={Color.BrandBlue500} />
            </ImgButton>
            <ImgButton onClick={increment}>
              <ForwardArrow fill={Color.BrandBlue500} />
            </ImgButton>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default HelpCarousel
