import { FunctionComponent, ReactElement } from 'react'
import { Field } from 'formik'
import styled from '@emotion/styled'
import { StyleObject } from 'shared/types'
import { Color } from 'shared/enums'
import LabeledFormElement from './LabeledFormElement'
import ErrorMessage, { StyledErrorMessage } from './ErrorMessage'

const styles = `
background: ${Color.White};
color: ${Color.Black};
font-size: 16px;
box-sizing: border-box;
padding: 15px 20px;
border: 1px solid rgba(22, 42, 56, 0.2);
border-radius: 4px;
outline: none;
:hover {
  border: 1px solid #1f94bf;
}
:focus {
  border: 1px solid ${Color.Black};
  color: ${Color.Black};
}
`

export const Textarea = styled.textarea`
  ${styles}
`

export const Input = styled.input`
  ${styles}
`

export const SmallInput = styled(Input)`
  padding: 7px 12px;
  font-size: 14px;
`

export type FormInputProps = {
  name: string
  label?: string
  placeholder?: string
  as?: unknown
  style?: StyleObject
  type?: string
  displayError?: boolean
  apiError?: string
  ariaLabel?: string
}

const error: (name: string, apiError?: string) => ReactElement = (name, apiError) => {
  return apiError ? (
    <StyledErrorMessage>{apiError}</StyledErrorMessage>
  ) : (
    <ErrorMessage forName={name} />
  )
}

const FormInput: FunctionComponent<FormInputProps> = ({
  name,
  label,
  placeholder,
  as = Input,
  style = {},
  type = 'text',
  displayError = true,
  apiError,
  ariaLabel,
}) => (
  <LabeledFormElement label={label} name={name}>
    <>
      <Field
        name={name}
        as={as}
        style={style}
        placeholder={placeholder}
        type={type}
        aria-label={ariaLabel}
      />
      {displayError && error(name, apiError)}
    </>
  </LabeledFormElement>
)

export default FormInput
