import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { SelectChangeEvent } from '@mui/material/Select'
import { map } from 'lodash/fp'
import { CardContainer } from 'shared/components/Card'
import { ColourCodingDisclaimer } from 'components/Disclaimer'
import FacetsProvider, { appendAllOption, Facets } from 'shared/components/FacetsProvider'
import { LinkPrimary } from 'shared/components/Link'
import Page from 'shared/components/Page'
import { Error, Loading } from 'shared/components/Placeholders'
import Select from 'shared/components/Select'
import useCustomDateFilter from 'shared/hooks/useCustomDateFilter'
import useFilterFactory, { FilterSelectInput } from 'shared/hooks/useFilterFactory'
import useQueryParams from 'shared/hooks/useQueryParams'
import { get, isSet } from 'shared/utils'
import { Path } from 'AppRouter'
import HelpCarousel, { Card } from 'components/HelpCarousel'
import { SampleCard } from 'components/SampleCard'
import { QueryResultsIndicator } from 'components/QueryResultsIndicator'
import { getQueryValue } from 'utils/api'
import { sortSamplesByOption } from 'utils/samples'
import { getTitle } from 'utils'
import { FacetName, Sample, SampleSortOption } from 'utils/types'

const SORT_URL_KEY = 'sort'

const FACETS_QUERY = gql`
  query {
    facets {
      currentCrop {
        label
        key
      }
      previousCrop {
        label
        key
      }
      farm {
        label
        key
      }
      requestor {
        label
        key
      }
    }
  }
`

const SAMPLES_QUERY = gql`
  query (
    $currentCrop: [String]
    $endTime: DateTime!
    $farm: [String]
    $previousCrop: [String]
    $requestor: [String]
    $startTime: DateTime!
  ) {
    samples(
      currentCrop: $currentCrop
      endTime: $endTime
      farm: $farm
      previousCrop: $previousCrop
      requestor: $requestor
      startTime: $startTime
    ) {
      id
      externalId
      sampleName
      order {
        externalId
        id
        sampleCount
        farm
        received
        reported
        rep {
          id
          code
          name
        }
      }
      field {
        currentCrop
        previousCrop
        size {
          unit
          value
        }
        strawRemoved
        projectedYield
      }
      standardSoilAnalysisResult {
        acidity
        p {
          index
          measurement {
            value
            unit
          }
        }
        k {
          index
          measurement {
            value
            unit
          }
        }
        mg {
          index
          measurement {
            value
            unit
          }
        }
      }
      additionalResults {
        analyte
      }
    }
  }
`

const SamplesSection: FC<{
  selectedFacets: Record<FacetName, Array<string> | string>
  startTime: string
  endTime: string
}> = ({ selectedFacets, startTime, endTime }) => {
  const navigate = useNavigate()

  const urlSortOption = get(useQueryParams(), SORT_URL_KEY)

  const { previousCrop, currentCrop, farm, requestor } = selectedFacets

  const [sortOption, setSortOption] = useState<SampleSortOption>(
    urlSortOption || SampleSortOption.Reported
  )

  const { loading, error, data } = useQuery(SAMPLES_QUERY, {
    variables: {
      currentCrop: getQueryValue(currentCrop),
      endTime,
      farm: getQueryValue(farm),
      previousCrop: getQueryValue(previousCrop),
      requestor: getQueryValue(requestor),
      startTime,
    },
    fetchPolicy: 'no-cache',
  })

  if (loading) return <Loading />
  if (error) return <Error />

  const samples = data?.samples
  const hasResults = isSet(samples)

  const sorted = sortSamplesByOption(samples, sortOption)

  return (
    <>
      <div className="flex items-center">
        <div className="grow">
          <QueryResultsIndicator length={samples?.length} singular="Result" />
        </div>
        <div>
          {hasResults && (
            <Select
              name="sample-sort"
              prependRenderValue="Sort by:"
              multiple={false}
              Input={<FilterSelectInput />}
              value={[sortOption]}
              onChange={({ target: { value } }: SelectChangeEvent<string[]>) => {
                setSortOption(value as SampleSortOption)
                navigate(`${Path.Results}?${SORT_URL_KEY}=${value}`)
              }}
              selectOptions={[
                { label: 'Reported', value: SampleSortOption.Reported },
                { label: 'Farm', value: SampleSortOption.Farm },
                { label: 'Requestor', value: SampleSortOption.Requestor },
                { label: 'Previous Crop', value: SampleSortOption.PreviousCrop },
                { label: 'Current Crop', value: SampleSortOption.CurrentCrop },
              ]}
            />
          )}
        </div>
      </div>

      <CardContainer>
        {map((sample: Sample) => <SampleCard sample={sample} key={sample.id} />)(sorted)}
      </CardContainer>
      {hasResults && <ColourCodingDisclaimer />}
    </>
  )
}

const SamplesPage: FC<{ facets: Facets }> = ({ facets }) => {
  const { farm, requestor, previousCrop, currentCrop } = facets

  const { Filters, selectedFacets } = useFilterFactory(Path.Results, {
    farm,
    requestor,
    previousCrop,
    currentCrop,
  })

  const {
    Component: CustomDateRange,
    startTime,
    endTime,
    key,
  } = useCustomDateFilter(Path.Results, 'Reported')

  return (
    <Page testId="ResultsPage" title={getTitle('Results')}>
      <div className="hidden md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-x-4">
        <Card>
          <h3>Welcome to the Cawood Hub</h3>
          <p className="text-text-secondary">
            Giving you easy access to your results and recommendations whether you’re at your desk
            or in the field.
          </p>
        </Card>
        <Card className="hidden lg:block">
          <h3>Soil Summary</h3>
          <p className="text-text-secondary">
            As the UK’s leading provider of agronomic analysis, NRM publishes summarised data from
            hundreds of thousands of soil samples from farms across the country each year. Use this
            tool to benchmark your results against our national and regional data.
          </p>
          <LinkPrimary to="http://soilsummary.enidata.co.uk/">
            View our benchmarking tool
          </LinkPrimary>
        </Card>
        <HelpCarousel />
      </div>
      <h2 className="pt-md">Results</h2>
      <Filters additionalFilters={[<CustomDateRange key={key} />]} />
      <SamplesSection selectedFacets={selectedFacets} startTime={startTime} endTime={endTime} />
    </Page>
  )
}

export const Samples: FC = () => (
  <FacetsProvider
    query={FACETS_QUERY}
    facetsKey="facets"
    render={(facets) => {
      const withAll = appendAllOption(facets, {
        previousCrop: {
          optional: true,
        },
        currentCrop: {
          optional: true,
        },
        farm: {
          optional: true,
        },
        requestor: {
          optional: true,
        },
      })

      return <SamplesPage facets={withAll} />
    }}
  />
)
