import { ensureArray } from '.'

// supports varying "all" filter options for different callers
export const getQueryValue: (
  allFilterOption: string
) => (selectedValue: string | string[]) => string | string[] | null =
  (allFilterOption) => (selectedValue) => {
    const isAllSelected =
      ensureArray(selectedValue)[0] === allFilterOption || ensureArray(selectedValue).length === 0

    return isAllSelected ? null : selectedValue
  }
