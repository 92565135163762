import { FC } from 'react'
import dayjs from 'dayjs'
import { map } from 'lodash/fp'
import { LinkPrimary } from 'shared/components/Link'
import { ReactComponent as LargeDarkLogo } from 'shared/assets/svg/nrm-logo-large-dark.svg'
import { Link } from 'enums'

type LinkType = { label: string; url: string }

const links: Array<LinkType> = [
  {
    label: 'Contact',
    url: Link.Contact,
  },
  {
    label: 'Legal',
    url: Link.Legal,
  },
  {
    label: 'Terms',
    url: Link.Terms,
  },
  {
    label: 'Privacy',
    url: Link.Privacy,
  },
  {
    label: 'Cookies',
    url: Link.Cookies,
  },
  {
    label: 'Order Supplies',
    url: Link.Supplies,
  },
  {
    label: 'Book Courier',
    url: Link.Courier,
  },
]

export const Footer: FC = () => (
  <div className="flex flex-col gap-y-md">
    <div className="flex gap-x-xxs">
      <div className="grid grid-cols-2 md:flex flex-wrap gap-x-md gap-y-xs grow self-end">
        {map((link: LinkType) => {
          const { url, label } = link
          return (
            <LinkPrimary to={url} className="font-semibold whitespace-nowrap" key={label}>
              {label}
            </LinkPrimary>
          )
        })(links)}
      </div>
      <LargeDarkLogo className="inline min-w-[125px] transform scale-75 md:transform-none" />
    </div>
    <div className="h-px bg-border-default"></div>
    <div className="text-text-secondary text-sm">
      © {dayjs().format('YYYY')} Cawood Scientific Limited
    </div>
  </div>
)
