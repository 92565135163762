import { FC } from 'react'
import { gql, useQuery } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { capitalize, map } from 'lodash/fp'
import { ReactComponent as RightArrowIcon } from 'shared/assets/svg/right-arrow.svg'
import { LabeledValueCollection } from 'shared/components/LabeledValue'
import { HierarchyLinks } from 'shared/components/Link'
import Page, { SectionBorder } from 'shared/components/Page'
import { Loading, Error } from 'shared/components/Placeholders'
import { Path } from 'AppRouter'
import { Sample } from 'utils/types'
import DownloadButtonData from 'components/DownloadButtonData'
import DownloadButtonReport from 'components/DownloadButtonReport'
import { getTitle } from 'utils'
import { formatDate as format } from 'utils/ui'

export const ORDER_QUERY = gql`
  query ($id: ID!) {
    order(id: $id) {
      id
      externalId
      farm
      received
      rep {
        id
        code
        name
      }
      reported
      samples {
        id
        externalId
        sampleName
      }
      status
    }
  }
`

export const OrderDetails: FC = () => {
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const { loading, error, data } = useQuery(ORDER_QUERY, {
    variables: {
      id,
    },
  })

  if (loading) return <Loading />
  if (error) return <Error />

  const { order } = data
  const { externalId, farm, received, rep, reported, samples, status } = order

  const breadcrumbs = (
    <HierarchyLinks paths={[{ to: Path.Orders, copy: 'Orders' }]} current={externalId} />
  )

  return (
    <Page
      testId="OrderDetails"
      title={getTitle('Order Details')}
      bodyClassName="gap-y-lg md:gap-y-xxl"
      breadcrumbs={breadcrumbs}
    >
      <div className="flex flex-col sm:flex-row gap-y-xs">
        <h1 className="grow">{externalId}</h1>
        <div className="flex">
          <div className="pr-2">Status:</div>
          <div className="font-semibold">{capitalize(status)}</div>
        </div>
      </div>
      <LabeledValueCollection
        classNames={{
          container: 'grid grid-cols-2 gap-lg md:grid-cols-4',
        }}
        collection={[
          ['Farm', farm],
          ['Requestor', rep.name, rep.code],
          ['Received', format(received)],
          ['Reported', format(reported)],
        ]}
      />
      <SectionBorder />
      <div className="flex flex-col gap-y-lg md:flex-row md:flex-row-reverse">
        <div className="flex gap-sm flex-wrap md:flex-nowrap [&>*]:w-full [&>*]:md:w-fit">
          <DownloadButtonReport orderId={order.id} />
          <DownloadButtonData order={order} />
        </div>
        <h2 className="flex items-center grow">Samples: {samples.length}</h2>
      </div>
      <div className="flex flex-col gap-y-2">
        {map((sample: Sample) => {
          const { id, externalId, sampleName } = sample
          return (
            <div
              key={`${externalId}-${sampleName}`}
              className="p-md border rounded-md border-black flex cursor-pointer shadow"
              onClick={() => navigate(`${Path.Results}/${id}`)}
            >
              <div className="grow">
                <h3>{sampleName}</h3>
                <div className="text-sm leading-5">{externalId}</div>
              </div>
              <div className="flex items-center bg-bg-secondary px-3 py-2 rounded-full fill-link-default">
                <RightArrowIcon fill="current" />
              </div>
            </div>
          )
        })(samples)}
      </div>
    </Page>
  )
}
